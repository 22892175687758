import React, {useState} from 'react';

const Card = ({title, icon, rightContent, children, ...rest}) => {
  return (
    <div className="bg-gray-100 p-1 rounded">
      <div className="flex flex-row items-center bg-white py-2 px-4 rounded">
        {icon && <div className="pr-2">{icon}</div>}
        <p className="text-sm flex-grow bold-text bigger-text">{title}</p>
        <div className="self-end">{rightContent}</div>
      </div>
      <div className="px-2 py-4" style={{padding: 15}}>{children}</div>
    </div>
  );
};

export default Card;
