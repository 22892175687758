import React, { useState, useMemo, useEffect } from 'react';
import {
  useTable,
  useRowSelect,
  usePagination,
  useAsyncDebounce,
} from 'react-table';
import { ContentWrapper, Button, SelectType, Input } from '@components';
import { ReactComponent as User } from '@images/user.svg';
import {
  getProducts,
  addProductApi,
  delProductApi,
  UpdateProductApi,
  PATCHUpdateProductApi,
} from '@apis';
import Switch from 'react-switch';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';

import { API_HOST } from '@apis/';
const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const notificationTypes = [
  { label: 'off', value: 'off' },
  { label: 'on', value: 'on' },
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
};
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = ({
  columns,
  data,
  isLoading,
  controlledPageCount,
  manualPagination = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setHiddenColumns,
    state: { pageIndex, pageSize },
    prepareRow,
    selectedFlatRows,

    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      manualPagination,
      // pageCount: controlledPageCount,
    },
    usePagination,

    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  return (
    <div>
      <table {...getTableProps()} className="min-w-full">
        <thead className="bg-tableHeaderBg p-4">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-4 whitespace-nowrap text-sm">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Products = () => {
  const [selectedType, setSelectedType] = useState(notificationTypes[0].value);

  const handleTypeSelection = item => {
    setSelectedType(item);
  };
  const history = useHistory();
  const rowclick = id => {
    var id = id;
    history.replace('/products/' + id);
  };

  const [name, setName] = useState('');
  const [cost, setCost] = useState(0);
  const [stock, setStock] = useState(0);
  const [description, setDescription] = useState('');
  const [enabled, setEanbled] = useState(false);
  const [gift, setGift] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [tdata, settData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [newProductID, setNewProductID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [is_gift_new, setIsGiftNew] = useState(false);

  const fetchData = () => {
    const token =
      localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');
    setLoading(true);
    let config = {
      url: API_HOST + `/admin-api/v1/products/?page=${pageNumber}`,
      method: 'get',
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config).then(res => {

      settData(res.data.results);
      setNextPage(res.data.next);
      setPreviousPage(res.data.previous);
      setLoading(false);
      setName('');
      setCost(0);
      setStock(0);
      setDescription('');
      setEanbled(false);
    });
  };

  const openModal = async () => {
    setIsOpen(true);
  };
  const closeModal = async () => {
    setIsOpen(false);
  };
  const SubmitProduct = useMutation(params => addProductApi(params));
  const params = {
    name: name,
    description: description,
    points_cost: cost,
    available_stock: stock,
    enabled: enabled,
    is_gift: is_gift_new
  }
  let ImageFormData = new FormData();

  ImageFormData.append('image', image);
  const config = {
    url: API_HOST + '/admin-api/v1/products/',
    method: 'post',
    body: params,
    headers: {
      'content-type': 'application/json',
      Authorization: `Token ${auth_token}`,
    },
    data: params,
  };

  const createProduct = async e => {
    e.preventDefault();
    axios(config)
      .then(res => {
        axios({
          url:
            API_HOST + '/admin-api/v1/products/' + res.data.id + '/add-image/',
          method: 'post',
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Token ${auth_token}`,
          },
          data: ImageFormData,
        });

        setIsOpen(false);
      })
      .then(closeModal())
      .then(toast.success("Product added successfully!"))
      .then(setTimeout(() => {
        setRefresh(!refresh)
      }, [1500]))
      .catch(err => {
        console.log(err);
        setIsOpen(false);
        toast.error(err);
      });
  };

  const Addproduct = async e => {
    e.preventDefault();
    if (name == '' || description == '') {
      toast.error('Please input all values');
      return;
    }
    if (/[^a-zA-Z]/.test(name)) {
      toast.error('Name field can only contain alphabets. Please try again');
      return;
    }

    await SubmitProduct.mutateAsync({
      name: name,
      points_cost: cost,
      available_stock: stock,
      description: description,
      enabled: enabled,
      product_images: image,
      is_gift: true,
    });
    setIsOpen(false);
    fetchData();
  };

  const RemoveProduct = async id => {
    await delProductApi(id)
    toast.success('Product Deleted Successfully');
    fetchData();
  };

  const SubmitProductup = useMutation(params => PATCHUpdateProductApi(params));
  const submit = async (val, id) => {
    var enableStatus = false;
    if (id) {
      enableStatus = false;
    } else {
      enableStatus = true;
    }
    await SubmitProductup.mutateAsync({
      id: val.id,
      name: val.name,
      points_cost: val.cost,
      available_stock: val.stock,
      description: val.description,
      enabled: enableStatus,
    });
    fetchData();
  };
  const Enabled = async (row, checked) => {
    submit(row, checked);
  };
  useEffect(() => {
    fetchData();
    setIsLoaded(true);
    // fetchProducts();
  }, [refresh, pageNumber]);

  function alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zA-Z]/i);
    return pattern.test(value);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'images',
        Cell: ({ row }) => (
          <div
            className="flex items-center"
            onClick={() => rowclick(row.values.id)}>
            {row.values.images[0] ? (
              <img
                src={row.values.images[0]['image']}
                alt="User avatar"
                style={{ width: '50px' }}
              />
            ) : (
              <User className={`fill-current text-menu`} />
            )}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'name',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div className="px-4">
              <p className="text-left">{value}</p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'enabled',
        Cell: ({ row }) => (
          <p className="text-left">
            <Switch
              onChange={() => Enabled(row.values, row.values.enabled)}
              checked={row.values.enabled}
            />
          </p>
        ),
      },
      { Header: 'Cost', accessor: 'points_cost' },
      { Header: 'Purchased', accessor: 'purchased_count' },
      { Header: 'Available', accessor: 'available_stock' },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div className="px-4">
              <p className="text-left">
                <p
                  className="tabbed-link"
                  style={{ marginLeft: -15 }}
                  onClick={() => RemoveProduct(value)}>
                  Remove
                </p>
              </p>
            </div>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <ContentWrapper
      breadcrumb={['Dashboard', 'Products']}
      addProduct={true}
      onButtonOneClick={openModal}>
      <div className="flex flex-col w-full">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}>
          <form>
            <h2> Add Product</h2>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              label="Name"
              type="text"
            />
            <Input
              value={cost}
              type="number"
              onChange={e => setCost(e.target.value)}
              label="Point Cost"
            />
            <Input
              value={stock}
              type="number"
              onChange={e => setStock(e.target.value)}
              label="Available stock"
            />
            <Input
              value={description}
              onChange={e => setDescription(e.target.value)}
              label="Description"
            />
            <Input
              // value=
              type="file"
              onChange={e => setImage(e.target.files[0])}
              label="Add product Image"
            />
            <div className="checkbox-row">
              <div>
                <label className="checkbox-label">Enabled</label>
              </div>
              <div>
                <Input
                  type="checkbox"
                  value={enabled}
                  onChange={e => setEanbled(!enabled)}
                />
              </div>
            </div>
            <div className="checkbox-row">
              <div>
                <label className="checkbox-label">Is a gift?</label>
              </div>
              <div>
                <Input
                  type="checkbox"
                  value={is_gift_new}
                  onChange={e => setIsGiftNew(!is_gift_new)}
                />
              </div>
            </div>
            <Button label="Save" onClick={createProduct} type="submit" />
          </form>
        </Modal>
        {loading ? (
          <div>
            <h1>Loading</h1>
          </div>
        ) : (
          <div>
            <Table columns={columns} data={tdata} />
            {tdata && tdata.length === 0 ? < div >
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${previousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${nextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setPageNumber(pageNumber + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </div>
        )}
      </div>
    </ContentWrapper >
  );
};

export default Products;
