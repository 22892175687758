import React, { useState, useEffect } from 'react';
import { ContentWrapper, Button, Input, SelectType } from '@components';
import DeleteIcon from '../../assets/images/delete.png';
import { getShopUsers, sendNotification } from '@apis';
import Multiselect from 'multiselect-react-dropdown';
import { toast } from 'react-hot-toast';

const notificationTypes = [
  { label: 'Off', value: 'off' },
  { label: 'SMS', value: 'sms' },
  { label: 'Push', value: 'push' },
  { label: 'All', value: 'all' },
];

const Marketing = () => {
  const [selectedType, setSelectedType] = useState(notificationTypes[0].value);
  const [users, setUsers] = useState([]);
  const [shopUsers, setShopUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [data, setData] = useState({
    users: [],
    custom_title: '',
    custom_body: '',
    notification_type: 'off',
  });

  const handleTypeSelection = item => {
    setSelectedType(item);
    setData({ ...data, notification_type: item });
  };

  const fetchData = async () => {
    const resp = await getShopUsers();
    setUsers(resp.results);

    var array = [];
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    var users_array = [];
    users.forEach(function (item, index) {
      users_array.push({
        name: item.user.first_name + ' ' + item.user.first_name,
        id: item.user.id,
      });
    });
    setShopUsers(users_array);
  }, [users]);

  const onSelectUser = (selectedList, selectedItem) => {

    selectedUsers.indexOf(selectedItem.id) === -1
      ? setData({ ...data, users: [...data.users, selectedItem.id] })
      : console.log('This item already exists');


  };

  const onRemoveUser = (selectedList, removedItem) => {
    var array = [...data.users];
    var index = array.indexOf(removedItem.id);
    if (index !== -1) {
      array.splice(index, 1);
      setData({ ...data, users: array });
    }
  };

  const sendNotif = () => {
    sendNotification(data)
      .then(() => {
        toast.success('Notification sent succesfully');
      })
      .catch(e => {
        toast.error(e);
      });
  };

  return (
    <ContentWrapper breadcrumb={["Dashboard", "Marketing"]} hasSearch={false}>
      <div>
        <div className="bg-tableHeaderBg rounded">
          <p className="uppercase text-sm px-4 py-3 text-menu">
            Notification settings <span style={{ marginLeft: '25%', color: '#B5B5C3' }}>Send using</span> <span style={{ marginLeft: '25%', color: '#B5B5C3' }}>Notification</span>
          </p>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            New products
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Product discount
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Points added
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Redeem request approved
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Use reward points
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Birthday
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            Referral
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10 }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
        </div>


      </div>
      <br />
      <div>
        <div className="bg-tableHeaderBg rounded">
          <p className="uppercase text-sm px-4 py-3 text-menu">
            Send Custom Notification
          </p>
        </div>
        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow">
            <Multiselect
              options={shopUsers}
              onSelect={onSelectUser}
              onRemove={onRemoveUser}
              displayValue="name"
              placeholder={'Select Users'}
              style={{
                inputField: {
                  margin: 0,
                  padding: 0,
                },
                searchBox: {
                  marginTop: 10,
                  pdding: 0,
                  height: 32,
                  borderColor: '#E5E7EB',
                  fontSize: 16,
                },
                multiselectContainer: {
                  margin: 0,
                  padding: 0,
                },
                chips: {
                  margin: 0,
                  padding: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                  fontSize: 14,
                  backgroundColor: '#9ac984',
                },
                option: {
                  padding: 4,
                  fontSize: 14,
                },
              }}
            />
          </div>
          <div
            className="mr-10"
            style={{ textAlign: 'right', marginRight: -10, marginTop: 10 }}>
            <Button
              onClick={() => {
                sendNotif();
              }}
              label="Send Notification"
              type="primary"
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-center px-4">
          <div className="flex-grow" style={{ maxWidth: '30%' }}>
            New products
          </div>
          <div className="" style={{ marginRight: 10 }}>
            <SelectType
              items={notificationTypes}
              onSelect={handleTypeSelection}
              selected={selectedType}
            />
          </div>
          <div className="flex-grow" style={{ marginRight: 10, flexDirection: 'column' }}>
            <Input
              placeholder="Enter Notification Title"
              value={data.custom_title || ''}
              onChange={e => setData({ ...data, custom_title: e.target.value })}
            />
          </div>
          <img src={DeleteIcon} alt="delete" style={{ width: 15, height: 15, marginTop: 0, marginRight: 10, cursor: 'pointer' }} title="Delete this item" />
        </div>
        <div style={{ marginTop: 15 }}>
          <p className='tabbed-link'>Add a notification</p>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Marketing;
