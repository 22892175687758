import { loginApi, loginApiNew } from '@apis';
import { Button, Checkbox, Input } from '@components';
import useAuth from '@context/useAuth';
import logo from '@images/nls-logo.png';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Eye, EyeOff } from "react-feather";

const Login = () => {

  const { onLogin } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loginPasswordVisibility, setLoginPasswordVisibility] = useState(false);

  const { state } = useLocation();
  const history = useHistory();

  const login = useMutation(params => loginApi(params));

  useEffect(() => {
    if (login?.isError) {
      if (typeof login?.error === 'string') {
        toast.error(login?.error);
      } else {
        toast.error('Login Error');
      }
      login?.reset();
    }


    if (login?.isSuccess) {
      onLogin({ token: login?.data?.key, remember });
      history.replace('/');
      history.replace(state?.from?.pathname);
    }
  }, [history, login, onLogin, remember, state?.from?.pathname]);

  const handleLogin = async e => {
    e.preventDefault();
    await login.mutate({
      email,
      password,
    });

  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginWindow flex justify-center items-center">
        <div
          style={{ width: 494 }}
          className="flex borderRadiusLeft5 flex-auto flex-col items-center justify-center next-gradient h-screen p-16 relative">
          <div className="w-full items-center my-4 px-20">
            <h1 className="font-bold text-3xl text-white text-center">
              Welcome
            </h1>
            <p className="font-light text-xs text-white text-center py-3">
              Login to your account
            </p>
          </div>
          <div className="w-full items-center">
            <Input
              dark
              white
              value={email}
              onChange={e => setEmail(e.target.value)}
              label="Email"
            />
            <div className='position-rel'>
              <Input
                dark
                white
                type={loginPasswordVisibility ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                label="Password"
                onKeyDown={handleKeyDown}
              />
              <div
                className="password-toggle"
                onClick={() => {
                  setLoginPasswordVisibility(
                    !loginPasswordVisibility
                  );
                }}
              >
                {loginPasswordVisibility ? (
                  <Eye size="14" color="black" />
                ) : (
                  <EyeOff size="14" color="black" />
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Checkbox
                checked={remember}
                onChange={e => setRemember(e.target.checked)}
                label="Remember Me"
              />
              <Link
                to="/forgot-password"
                className="text-white text-xs px-2 underline">
                Forgot Password?
              </Link>
            </div>
          </div>
          <div
            className="w-full inset-x-0 bottom-24"
            style={{ margin: '10vh 60px' }}>
            <Button
              block
              loading={login?.isLoading}
              disabled={login?.isLoading || !email || !password}
              onClick={handleLogin}
              label="Sign In"
            />
            <div className="flex flex-row justify-center items center my-5">
              <p className="text-white text-xs">Don't have an account?</p>
              <Link
                to="/register"
                className="text-white text-xs px-2 underline">
                Sign up now
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{ width: 400 }}
          className="flex borderRadiusRight5 flex-grow-1 justify-center items-center next-gradient h-screen p-4">
          <img
            className="object-contain h-80 loginLogo"
            src={logo}
            alt="Next Level Technology Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
