import React, {createContext, useContext, useMemo} from 'react';
import {useQuery} from 'react-query';

import {getStoreApi} from '@apis';

import useAuth from './useAuth';

export const StoreContext = createContext({});

export const StoreProvider = ({children}) => {
  const {isAuthenticated} = useAuth();

  const {data: storeDetails, isLoading, error, refetch} = useQuery(
    'fetchStoreDetails',
    getStoreApi,
    {
      enabled: !!isAuthenticated,
    },
  );

  const memoedValue = useMemo(
    () => ({storeDetails, error, isLoading, refetch}),
    [storeDetails, error, isLoading, refetch],
  );

  return (
    <StoreContext.Provider value={memoedValue}>
      {!isLoading && children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export default useStore;
