import {
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  Dashboard,
  Approve,
  StoreSetup,
  PointsSetup,
  Marketing,
  Analytics,
  ShopUsers,
  ShopUsersDetail,
  Products,
  Productdetails,
} from '@screens/';
import CheckMail from '@screens/Auth/checkMail';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useAuth from '@context/useAuth';
import { Layout } from '@components';

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const Routes = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Switch>
      {/* <Route exact path="/login">
        <Login />
      </Route> */}
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/email-sent">
        <CheckMail />
      </Route>
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>
      <Layout>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute exact path="/store-setup">
          <StoreSetup />
        </PrivateRoute>
        <PrivateRoute exact path="/approve-redeems">
          <Approve />
        </PrivateRoute>
        <PrivateRoute exact path="/points-setup">
          <PointsSetup />
        </PrivateRoute>
        <PrivateRoute exact path="/shop-users">
          <ShopUsers />
        </PrivateRoute>
        <Route
          exact
          path="/shop-users/:id"
          render={({ match, location }) => {
            return isAuthenticated ? (
              <ShopUsersDetail params={match.params.id} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            );
          }}
        />
        <Route
          exact
          path="/products/:id"
          render={({ match, location }) => {
            return isAuthenticated ? (
              <Productdetails params={match.params.id} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            );
          }}
        />
        <PrivateRoute exact path="/marketing">
          <Marketing />
        </PrivateRoute>
        <PrivateRoute exact path="/analytics">
          <Analytics />
        </PrivateRoute>
        <PrivateRoute exact path="/products">
          <Products />
        </PrivateRoute>
      </Layout>
    </Switch>
  );
};

export default Routes;
