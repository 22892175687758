import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { ContentWrapper, Button, SelectType, Switch, Input } from '@components';
import moment from 'moment';
import {
  getProducts,
  ImgUploadtApi,
  delProductApi,
  getProductDetail,
  UpdateProductApi,
  getProductReview,
  removeReview,
} from '@apis';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import axios from 'axios';
import { API_HOST } from '@apis/';
import './products.css';
const notificationTypes = [
  { label: 'off', value: 'off' },
  { label: 'on', value: 'on' },
];

const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
};
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({ columns, data }, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  return (
    <table {...getTableProps()} className="min-w-full">
      <thead className="bg-tableHeaderBg p-4">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 whitespace-nowrap text-sm">
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Products = props => {
  const [selectedType, setSelectedType] = useState(notificationTypes[0].value);

  const handleTypeSelection = item => {
    setSelectedType(item);
  };
  const [filecontent, setFileContent] = useState();
  const [name, setName] = useState('');
  const [points_cost, setCost] = useState(0);
  const [available_stock, setStock] = useState(0);
  const [description, setDescription] = useState('');
  const [enabled, setEanbled] = useState(false);
  const [images, setImage] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [sloading, setSloading] = useState(true);
  const [isEditedView, setEditedView] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);

  const [newEnabled, setNewEnabled] = useState(false);
  const [isGift, setIsGift] = useState(false);

  const fetchData = async () => {

    const review = await getProductReview(props.params);

    if (review?.results.length > 0) {
      let data = review.results.map(e => {
        e.created_at = moment(e.created_at).format('YYYY-MM-DD');
        return e;
      });
      setData(review.results);
    }


    setLoading(false);
  };
  const imageupload = useMutation(params => ImgUploadtApi(params));
  const onFileChange = event => {
    let formdata = new FormData();
    formdata.append('image', event.target.files[0]);

    imageupload.mutateAsync({ id: props.params, formdata: formdata });

    // window.location.reload();
  };

  const SubmitProduct = useMutation(params => UpdateProductApi(params));

  const EditProduct = async e => {

    if (name == '' || description == '') {
      toast.error('Please input all values');
    } else {
      await SubmitProduct.mutateAsync({
        id: props.params,
        name: name,
        points_cost: points_cost,
        available_stock: available_stock,
        description: description,
        enabled: newEnabled,
        is_gift: isGift,
      });
      // await imageupload.mutateAsync({
      //   id:props.params,
      //   formData:formData
      // });
    }
    fetchData();
  };

  // Fetch all the images associated with a particular product

  const config = {
    url: API_HOST + `/admin-api/v1/products/${props.params}/images/`,
    method: 'get',
    headers: {
      Authorization: `Token ${auth_token}`,
    },
  };

  const config2 = {
    url: API_HOST + `/admin-api/v1/products/${props.params}/`,
    method: 'get',
    headers: {
      Authorization: `Token ${auth_token}`,
    },
  };

  const getProductInfo = () => {
    axios(config2).then(res => {
      setName(res.data.name);
      setCost(res.data.points_cost);
      setDescription(res.data.description);
      setStock(res.data.available_stock);
      setIsGift(res.data.is_gift);
      setNewEnabled(res.data.enabled);
    });
  };

  const getProductImages = () => {
    axios(config)
      .then(
        res => setProductImages(res.data),
        setSloading(false)
      )
      .catch(err => console.log(err));
  };

  const DelProd = useMutation(params => delProductApi(params));
  const history = useHistory();
  const RemoveProduct = async () => {
    await DelProd.mutateAsync(props.params);
    setTimeout(() => {
      history.replace('/products');
    }, 1000);

  };

  const Cancel = async () => {
    history.replace('/products');
  };

  useEffect(() => {
    // fetchData();
    getProductImages();
    getProductInfo();
  }, []);

  useEffect(() => {
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const RemoveReview = async id => {
    var config = {
      url: API_HOST + `/admin-api/v1/product-review/${id}/delete`,
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        // 'cache-control': 'no-cache',
        Authorization: `Token ${auth_token}`,
      },
    };
    let data = await axios(config)
      .then(res => res)
      .catch(e => console.log(e));

    if (data) {
      window.location.reload(false);
      fetchData();
    }
  };

  const handleOnChange = () => {
    setNewEnabled(!newEnabled);
  };

  const handleOnChangeIsGift = () => {
    setIsGift(!isGift);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'USER',
        accessor: 'author_name',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div className="px-4">
              <p className="text-left">
                {value}
                {/* {value.first_name} {value.author_name} */}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'SCORE',
        accessor: 'rating',
      },
      { Header: 'DATE', accessor: 'created_at' },
      { Header: 'COMMENT', accessor: 'comment' },
      {
        Header: 'ACTION',
        accessor: 'id',
        Cell: value => (
          <div>
            <Button
              label="Remove"
              onClick={() => {
                RemoveReview(value.row.original.id);
              }}
              type="submit"
            />
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <ContentWrapper
      breadcrumb={['Dashboard', 'Products', name]}
      productDetail={true}
      hasSearch={false}
      removeShopUser={false}
      onButtonThreeClick={RemoveProduct}
      onButtonOneClick={EditProduct}
      onButtonTwoClick={Cancel}
      isEditedView={isEditedView}
    >
      <div className="flex flex-col w-full">
        <div className="product-images-row">
          {!sloading &&
            productImages?.map(item => (
              <div className="product-image-container">
                <img
                  className="object-contain"
                  src={item.image}
                  alt="Store Logo"
                />
              </div>
            ))}
          <div>
            <label htmlFor="image-upload">
              <div className="upload-button">
                <h4>Add image</h4>
              </div>
            </label>
          </div>
        </div>
      </div>
      <Input
        type="file"
        onChange={onFileChange}
        id="image-upload"
        // label="File Upload"
        hidden
      />
      <Input
        value={name}
        placeholder={!infoLoading && name}
        onChange={e => {
          setName(e.target.value);
          setEditedView(true);
        }}
        label="Name"
      />
      <Input
        value={points_cost}
        type="number"
        onChange={e => {
          setCost(e.target.value);
          setEditedView(true);
        }}
        label="Point Cost"
      />
      <Input
        value={available_stock}
        type="number"
        onChange={e => {
          setStock(e.target.value);
          setEditedView(true);
        }}
        label="Available stock"
      />
      <Input
        value={description}
        onChange={e => {
          setDescription(e.target.value);
          setEditedView(true);
        }}
        label="Description"
      />

      <div className="checkbox-row">
        <div>
          <label className="checkbox-label">Enabled</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="topping"
            name="topping"
            value={newEnabled}
            checked={newEnabled}
            onChange={handleOnChange}
          />
        </div>
      </div>
      {/*  */}
      <div className="checkbox-row">
        <div>
          <label className="checkbox-label">Is Gift</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="topping"
            name="topping"
            value={isGift}
            checked={isGift}
            onChange={handleOnChangeIsGift}
          />
        </div>
      </div>
      {/*  */}
      <div className='my-4'>
        <Button label="Save" onClick={EditProduct} type="submit" />
      </div>
      <div className='my-5'>
        <h5 className='mb-4'>Product Reviews</h5>
        {/* PAGINATION CODE */}
        <Table columns={columns} data={data} />
        {data && data.length === 0 && < div >
          <p className='text-center mt-5'>No reviews found</p>
        </div>}
      </div>
    </ContentWrapper>
  );
};

export default Products;
