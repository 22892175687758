import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { ContentWrapper, Button, Input } from '@components';
import moment from 'moment';
// import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ReactComponent as User } from '@images/user.svg';
import {
  awardManage,
  getProducts,
  getShopUsersDetail,
  getShopUsersMoneySpents,
  addMountSpentApi,
  getShopUsersPurchases,
  getShopUsersMoneySpentsBonus,
  giveBonusesApi,
  getShopUsersEarned,
  getShopUsersGifts,
  delShopUserApi,
  Acceptapi,
  AcceptPurchaseapi,
  getSingleUserPointList,
  changeRewardStatus,
  changeGiftStatus,
  ProductPurchaseNew
} from '@apis';
import { getFCP } from 'web-vitals';
import debby from '@images/debby.png';
import axios from 'axios';
import { API_HOST } from '@apis/';
import './users.css';
import { map } from 'lodash';

const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
};
const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({ columns, data }, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  return (
    <table {...getTableProps()} className="min-w-full">
      <thead className="bg-tableHeaderBg p-4">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 whitespace-nowrap text-sm">
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ShopUserDetail = props => {

  const { id } = useParams()

  const [acceptearn, setacceptearnData] = useState([]);
  const [rejectearn, setrejectearnData] = useState([]);
  const [acceptpurchase, setacceptpurchaseData] = useState([]);
  const [rejectpurchase, setrejectpurchaseData] = useState([]);
  const [moneyspent, setMoneySpent] = useState('');
  const [discount, setDiscount] = useState('');
  const [points, setPoint] = useState(0);
  const [gift, setGift] = useState(0);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [bonusmodalIsOpen, setbonusIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [spendData, setSpentData] = useState([]);
  const [spendbonusData, setSpentBonusData] = useState([]);
  const [PurchaseData, setPurchaseData] = useState([]);
  const [GiftData, setGiftData] = useState([]);
  const [RewardData, setRewardData] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [columns, setColumn] = useState('1');
  const [loading, setLoading] = useState(false);
  const [productoption, setProductOption] = useState([]);
  const [refresh, setRefresh] = useState(false);

  //Pagination
  const [rewardCurrentPage, setRewardCurrentPage] = useState(1)
  const [giftCurrentPage, setGiftCurrentPage] = useState(1)
  const [purchaseCurrentPage, setPurchaseCurrentPage] = useState(1)
  const [moneyCurrentPage, setMoneyCurrentPage] = useState(1)
  const [rewardNextPage, setRewardNextPage] = useState('');
  const [rewardpreviousPage, setRewardPreviousPage] = useState('');
  const [giftNextPage, setGiftNextPage] = useState('');
  const [giftpreviousPage, setGiftPreviousPage] = useState('');
  const [purchaseNextPage, setPurchaseNextPage] = useState('');
  const [purchasepreviousPage, setPurchasePreviousPage] = useState('');
  const [moneyNextPage, setMoneyNextPage] = useState('');
  const [moneypreviousPage, setMoneyPreviousPage] = useState('');
  const [rewardTotal, setRewardTotal] = useState(0)
  const [giftTotal, setGiftTotal] = useState(0)
  const [purchaseTotal, setPurchaseTotal] = useState(0)
  const [moneyTotal, setMoneyTotal] = useState(0)


  // const product
  const getUsers = async () => {
    let config = {
      url: API_HOST + `/admin-api/v1/shop-users/}`,
      //      url: API_HOST + '/admin-api/v1/shop-users/?page=${pageNumber}',
      method: 'get',
      headers: {
        Authorization: `Token ${auth_token}`,
      },
    };
    await axios(config)
      .then(res => res)
      .then(res => console.log(''));
  };

  useEffect(() => {
    (async () => {
      var config = {
        url: API_HOST + `/admin-api/v1/shop-users/${props.params}/reward-list/?page=${rewardCurrentPage}`,
        method: 'get',
        headers: {
          'content-type': 'application/json',
          // 'cache-control': 'no-cache',
          Authorization: `Token ${auth_token}`,
        },
      };
      let data = await axios(config).then(res => {
        if (res?.data?.results.length > 0) {
          let resu = res?.data?.results.map(e => {
            let points = 0;

            if (
              e.level_award.points !== undefined &&
              e.level_award.points !== null
            ) {
              points = e.level_award.points;
            }

            let gift = 0;
            if (
              e.level_award.gift !== undefined &&
              e.level_award.gift !== null
            ) {
              gift = e.level_award.gift;
            }

            let data_point = points != 0 ? `${points} Points` : '';

            let data_gift = gift != 0 ? `${gift} Gifts` : '';

            let text1 = data_point;
            let text2 = data_gift;
            let result = text1.concat(' ', text2);

            e.gift_oue = result;

            return e;
          });
          setRewardTotal(res.data.count)
          setRewardNextPage(res.data.next)
          setRewardPreviousPage(res.data.previous)
          setRewardData(resu);
        }
      });

      if (props.params) {
        let id = props.params;

        const fetchData = await getSingleUserPointList(id);

        // console.log('fetchData >>> ', fetchData);
      }
    })();
  }, [props, refresh, rewardCurrentPage]);

  useEffect(() => {
    (async () => {
      const awardManageresult = await awardManage();

      let resData = [];

      if (awardManageresult.length > 0) {
        awardManageresult.map(e => {
          if (e.awards.length > 0) {
            e.awards.map(i => {
              resData.push({
                label: i.award_type,
                value: i.id,
              });
            });
          }
        });
      }

      setProductOption(resData);
    })();
  }, [setProductOption]);

  //const awardManageresult = await awardManage();

  const getUserDetails = () => {
    var config = {
      url: API_HOST + `/admin-api/v1/shop-users/${props.params}/`,
      method: 'get',
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Token ${auth_token}`,
      },
    };

    // let sampleObj = {
    //   id: 28,
    //   is_active: true,
    //   level_display: '',
    //   pending_approval_count: 0,
    //   purchases_count: 0,
    //   referral_code: '9112AE35',
    //   referral_count: null,
    //   total_money_spent: 0,
    //   total_points: 0,
    //   user: {
    //     first_name: 'Prince',
    //     id: 11,
    //     is_active: true,
    //     last_name: 'Webapp',
    //     profile: {
    //       id: 12,
    //       profile_image:
    //         'https://next-level-services-24569.s3.amazonaws.com/media/pimgs/2022/10/17/profile_image.png',
    //     },
    //   },
    // };

    fetch(API_HOST + `/admin-api/v1/shop-users/${props.params}/user-balance/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth_token}`,
      },
    })
      .then(response => response.json())
      .then(function (data) {
        // console.log('response isss new apiii', data);

        //point_balance[0].level_display

        // if (data) {
        //   sampleObj.pending_approval_count = data.pending_count;
        //   sampleObj.purchases_count = data.purchase_count;
        //   sampleObj.total_money_spent = data.total_spent;
        //   sampleObj.referral_count = data.referral_code;
        //   sampleObj.total_points = data.points;
        //   // sampleObj.level = data.point_balance[0].level_display;
        //   sampleObj.level = data.selected_level;

        // }
        setUserData(data);
        //stuff
      })
      .catch(e => {
        setUserData(data);
      });

    // /admin-api/v1/shop-users/{id}/user-balance/
    axios(config).then(res => {
      // console.log('old api data ', props.params, res);
      // setUserData(res.data);
    });
  };

  const getUserMoneySpent = () => {
    var config = {
      url: API_HOST + `/admin-api/v1/shop-users/${props.params}/money-spents/?page=${moneyCurrentPage}`,
      method: 'get',
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Token ${auth_token}`,
      },
    };

    axios(config)
      .then(res => {
        setMoneyTotal(res.data.count)
        setMoneyNextPage(res.data.next)
        setMoneyPreviousPage(res.data.previous)
        setSpentData(res.data.results);
      })
      .catch(err => console.log('errr', err));
  };

  const getUserGifts = () => {
    var config = {
      url: API_HOST + `/admin-api/v1/shop-users/${props.params}/gifts/?page=${giftCurrentPage}`,
      method: 'get',
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Token ${auth_token}`,
      },
    };

    axios(config)
      .then(res => {
        setGiftTotal(res.data.count)
        setGiftNextPage(res.data.next)
        setGiftPreviousPage(res.data.previous)
        setGiftData(res.data.results);
      })
      .catch(err => console.log('errr', err));
  };


  const getUserPurchases = () => {
    var config = {
      url:
        API_HOST +
        `/admin-api/v1/shop-users/${props.params}/product-purchases/?page=${purchaseCurrentPage}`,
      method: 'get',
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Token ${auth_token}`,
      },
    };
    axios(config).then(res => {
      setPurchaseTotal(res.data.count)
      setPurchaseNextPage(res.data.next)
      setPurchasePreviousPage(res.data.previous)
      setPurchaseData(res.data.results);
    });
  };

  const fetchData = async () => {
    const resp = await getShopUsersDetail(props.params);
    const pro = await getProducts();
    const spentAmount = await getShopUsersMoneySpents(props.params);
    const PurchasedData = await getShopUsersPurchases(props.params);
    const rewardData = await getShopUsersEarned(props.params);
    const giftData = await getShopUsersGifts(props.params);

    var acceptreward_earned = [];
    var rejectreward_earned = [];
    var acceptreward_purchased = [];
    var rejectreward_purchased = [];

    if (rewardData.results.length > 0) {
      for (var i = 0; i < rewardData.results.length; i++) {
        rewardData.results[i].user.rewardid = rewardData.results[i].id;
        if (rewardData.results[i].accepted == false) {
          rejectreward_earned.push(rewardData.results[i]);
        } else if (rewardData.results[i].accepted == true) {
          acceptreward_earned.push(rewardData.results[i]);
        }
      }
    }
    if (PurchasedData.results.length > 0) {
      for (var j = 0; j < PurchasedData.results.length; j++) {
        PurchasedData.results[j].user.rewardid = PurchasedData.results[j].id;
        if (PurchasedData.results[j].accepted == false) {
          rejectreward_purchased.push(PurchasedData.results[j]);
        } else if (PurchasedData.results[j].accepted == true) {
          acceptreward_purchased.push(PurchasedData.results[j]);
        }
      }
    }
    if (giftData && giftData.results.length > 0) {
      setGiftData(giftData.results)
    } else {
      setGiftData([])
    }
    // var productitem = [];
    // if (pro.results.length > 0) {
    //   for (var m = 0; m < pro.results.length; m++) {
    //     productitem.push({
    //       label: pro.results[m].name,
    //       value: pro.results[m].id,
    //     });
    //   }
    // }
    // setProductOption(productitem);
    setacceptearnData(acceptreward_earned);
    setrejectearnData(rejectreward_earned);
    setacceptpurchaseData(acceptreward_purchased);
    setrejectpurchaseData(rejectreward_purchased);

    setRewardData(giftData.results);
    setSpentData(spentAmount.results);
    setPurchaseData(PurchasedData.results);
    setUserData(resp);
    setLoading(false);
  };
  const Acceptslected = async () => {
    setRewardData(acceptearn);
    setPurchaseData(acceptpurchase);
  };
  const Rejectslected = async () => {
    setRewardData(rejectearn);
    setPurchaseData(rejectpurchase);
  };
  const closebonusModal = async () => {
    setbonusIsOpen(false);
  };
  const openbonusModal = async () => {
    setbonusIsOpen(true);
  };
  const openModal = async () => {
    setIsOpen(true);
  };
  const closeModal = async () => {
    setIsOpen(false);
  };
  const history = useHistory();
  const SubmitmoumtSpent = useMutation(params => addMountSpentApi(params));
  const MoneySpent = async e => {
    e.preventDefault();
    if (amount == '' || note == '') {
      toast.error('Please input all values');
    } else {
      await SubmitmoumtSpent.mutateAsync({
        id: props.params,
        amount: amount,
        note: note,
      });
      setIsOpen(false);
    }
    fetchData();
  };
  const Submitbonus = useMutation(params => giveBonusesApi(params));
  const GiveBouns = async e => {
    e.preventDefault();
    if (moneyspent == '') {
      toast.error('Please input all values');
    } else {
      await Submitbonus.mutateAsync({
        id: props.params,
        money_spent: moneyspent,
        discount: discount === '' ? 0 : discount,
        points: points,
        gift: gift,
      });
      setbonusIsOpen(false);
    }
    fetchData();
  };
  const DelShopUser = useMutation(params => delShopUserApi(params));
  const RemoveUser = async id => {
    await DelShopUser.mutateAsync(props.params);
    toast.success('User removed successfully');
    history.push('/shop-users');
  };

  const RewardAccept = useMutation(params => Acceptapi(params));
  const PurchaseAccept = useMutation(params => ProductPurchaseNew(params));

  const acceptEarn = async id => {
    var paramdata = {
      status: 'accepted',
    };

    let res = await changeRewardStatus(id.original.id, paramdata);

    if (res) {
      var config = {
        url: API_HOST + `/admin-api/v1/shop-users/${props.params}/reward-list/`,
        method: 'get',
        headers: {
          'content-type': 'application/json',
          // 'cache-control': 'no-cache',
          Authorization: `Token ${auth_token}`,
        },
      };
      let data = await axios(config).then(res => {
        if (res?.data?.results.length > 0) {
          let resu = res?.data?.results.map(e => {
            let points = 0;

            if (
              e.level_award.points !== undefined &&
              e.level_award.points !== null
            ) {
              points = e.level_award.points;
            }

            let gift = 0;
            if (
              e.level_award.gift !== undefined &&
              e.level_award.gift !== null
            ) {
              gift = e.level_award.gift;
            }

            let data_point = points != 0 ? `${points} Points` : '';

            let data_gift = gift != 0 ? `${gift} Gifts` : '';

            let text1 = data_point;
            let text2 = data_gift;
            let result = text1.concat(' ', text2);

            e.gift_oue = result;

            return e;
          });

          setRewardData(resu);
        }
      });
    }
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
    // setPurchaseData(purchasedData.results);
  };
  const rejectEarn = async id => {
    var paramdata = {
      status: 'rejected',
    };

    let res = await changeRewardStatus(id.original.id, paramdata);

    if (res) {
      var config = {
        url: API_HOST + `/admin-api/v1/shop-users/${props.params}/reward-list/`,
        method: 'get',
        headers: {
          'content-type': 'application/json',
          // 'cache-control': 'no-cache',
          Authorization: `Token ${auth_token}`,
        },
      };
      let data = await axios(config).then(res => {
        if (res?.data?.results.length > 0) {
          let resu = res?.data?.results.map(e => {
            let points = 0;

            if (
              e.level_award.points !== undefined &&
              e.level_award.points !== null
            ) {
              points = e.level_award.points;
            }

            let gift = 0;
            if (
              e.level_award.gift !== undefined &&
              e.level_award.gift !== null
            ) {
              gift = e.level_award.gift;
            }

            let data_point = points != 0 ? `${points} Points` : '';

            let data_gift = gift != 0 ? `${gift} Gifts` : '';

            let text1 = data_point;
            let text2 = data_gift;
            let result = text1.concat(' ', text2);

            e.gift_oue = result;

            return e;
          });

          setRewardData(resu);
        }
      });
    }
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  const acceptGift = async id => {
    var paramdata = {
      status: 'accepted',
    };

    let res = await changeGiftStatus(id.original.id, paramdata);

    if (res) {
      // var config = {
      //   url: API_HOST + `/admin-api/v1/shop-users/${props.params}/reward-list/`,
      //   method: 'get',
      //   headers: {
      //     'content-type': 'application/json',
      //     // 'cache-control': 'no-cache',
      //     Authorization: `Token ${auth_token}`,
      //   },
      // };
      // let data = await axios(config).then(res => {
      //   if (res?.data?.results.length > 0) {
      //     let resu = res?.data?.results.map(e => {
      //       let points = 0;

      //       if (
      //         e.level_award.points !== undefined &&
      //         e.level_award.points !== null
      //       ) {
      //         points = e.level_award.points;
      //       }

      //       let gift = 0;
      //       if (
      //         e.level_award.gift !== undefined &&
      //         e.level_award.gift !== null
      //       ) {
      //         gift = e.level_award.gift;
      //       }

      //       let data_point = points != 0 ? `${points} Points` : '';

      //       let data_gift = gift != 0 ? `${gift} Gifts` : '';

      //       let text1 = data_point;
      //       let text2 = data_gift;
      //       let result = text1.concat(' ', text2);

      //       e.gift_oue = result;

      //       return e;
      //     });

      //     setRewardData(resu);
      //   }
      // });
      getUserGifts()
    }
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  const rejectGift = async id => {
    var paramdata = {
      status: 'rejected',
    };

    let res = await changeGiftStatus(id.original.id, paramdata);

    if (res) {
      // var config = {
      //   url: API_HOST + `/admin-api/v1/shop-users/${props.params}/reward-list/`,
      //   method: 'get',
      //   headers: {
      //     'content-type': 'application/json',
      //     // 'cache-control': 'no-cache',
      //     Authorization: `Token ${auth_token}`,
      //   },
      // };
      // let data = await axios(config).then(res => {
      //   if (res?.data?.results.length > 0) {
      //     let resu = res?.data?.results.map(e => {
      //       let points = 0;

      //       if (
      //         e.level_award.points !== undefined &&
      //         e.level_award.points !== null
      //       ) {
      //         points = e.level_award.points;
      //       }

      //       let gift = 0;
      //       if (
      //         e.level_award.gift !== undefined &&
      //         e.level_award.gift !== null
      //       ) {
      //         gift = e.level_award.gift;
      //       }

      //       let data_point = points != 0 ? `${points} Points` : '';

      //       let data_gift = gift != 0 ? `${gift} Gifts` : '';

      //       let text1 = data_point;
      //       let text2 = data_gift;
      //       let result = text1.concat(' ', text2);

      //       e.gift_oue = result;

      //       return e;
      //     });

      //     setRewardData(resu);
      //   }
      // });
      getUserGifts()
    }
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  const giftgetdata = async val => {
    setGift(val.value);
  };

  const acceptPurchase = async id => {
    console.log(id)
    if (id.original.points_paid > id.original.user_current_points) {
      toast.error("User doesn't have sufficient points to purchase this product!")
      return
    }
    var paramdata = {
      accepted: true,
    };

    let res = await ProductPurchaseNew(id.original.id, paramdata);

    fetchData();
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  const rejectPurchase = async id => {
    var paramdata = {
      accepted: false
    };

    let res = await ProductPurchaseNew(id, paramdata);
    fetchData();
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  const tablerewardcolumns = useMemo(
    () => [
      {
        Header: 'USER',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name} {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      { Header: 'RECEIVED', accessor: 'gift_oue' },
      { Header: 'DETAILS', accessor: 'code' },
      // {Header: 'REWARD TYPE', accessor: 'reward_type'},
      {
        Header: 'STATUS',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            {row.original.status == 'pending' ? (
              <div className="inline-flex items-center">
                <Button
                  onClick={() => acceptEarn(row)}
                  label="Accept"
                  type="primary"
                />
                <Button
                  onClick={() => {
                    rejectEarn(row);
                  }}
                  label="Reject"
                  type="secondary"
                />
              </div>
            ) : row.original.status == 'accepted' ? (
              <div>Accepted</div>
            ) : (
              <div>Rejected</div>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const tablegiftcolumns = useMemo(
    () => [
      {
        Header: 'USER',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name} {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Level', accessor: 'level', Cell: ({ value }) => (
          <div className='text-uppercase'>{value}
          </div>
        ),
      },
      { Header: 'Detail', accessor: 'code' },
      {
        Header: 'Date',
        accessor: 'created_at', Cell: ({ value }) => (
          <div>{moment(value).format('MM-DD-YYYY')}</div>
        )
      },
      {
        Header: 'STATUS',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            {row.original.status == 'pending' ? (
              <div className="inline-flex items-center">
                <Button
                  onClick={() => acceptGift(row)}
                  label="Accept"
                  type="primary"
                />
                <Button
                  onClick={() => {
                    rejectGift(row);
                  }}
                  label="Reject"
                  type="secondary"
                />
              </div>
            ) : row.original.status == 'accepted' ? (
              <div>Accepted</div>
            ) : (
              <div>Rejected</div>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const tablepurchasecolumns = useMemo(
    () => [
      {
        Header: 'USER',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            {value.profile && value.profile.profile_image !== null ?
              <img
                src={value.profile.profile_image}
                className="user-avatar"
                alt="User avatar"
              />
              :
              <img
                src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                className="user-avatar"
                alt="User avatar"
              />
            }
            <div className="px-4">
              <p className="text-left">
                {value.first_name} {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      { Header: 'PAID', accessor: 'points_paid' },
      {
        Header: 'PURCHASED',
        accessor: 'product_name',
      },
      // {Header: 'PAID', accessor: 'points_paid'},
      // {Header: 'DATE', accessor: 'created_at'},
      {
        Header: 'STATUS',
        accessor: row => ({
          accepted: row.accepted,
          productId: row.product_id
        }),
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            {row.original.accepted == null ? (
              <div className="inline-flex items-center">
                <Button
                  onClick={() => acceptPurchase(row)}
                  label="Accept"
                  type="primary"
                />
                <Button
                  onClick={() => {
                    rejectPurchase(row.original.id);
                  }}
                  label="Reject"
                  type="secondary"
                />
              </div>
            ) : row.original.accepted == true ? (
              <div>Accepted</div>
            ) : (
              <div>Rejected</div>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const tablemoneysentcolumns = useMemo(
    () => [
      {
        Header: 'AMOUNT SPENT',
        accessor: 'amount',
      },
      { Header: 'DISCOUNT', accessor: 'discount' },
      {
        Header: 'DATE', accessor: 'created_at', Cell: ({ value }) => (
          <div className='text-uppercase'>{moment(value).format('MM-DD-YYYY')}
          </div>
        ),
      },
      { Header: 'NOTE', accessor: 'note' },
    ],
    [],
  );

  const tablemoneysentbounscolumns = useMemo(
    () => [
      // {
      //   Header: 'LEVEL',
      //   accessor: 'money_spent',
      // },
      { Header: 'DATE', accessor: 'created_at' },
      { Header: 'BONUS', accessor: 'gift_name' },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
    getUserDetails();
    getUserMoneySpent();
    getUserGifts();
    getUserPurchases();
  }, [refresh, giftCurrentPage, purchaseCurrentPage, moneyCurrentPage]);

  useEffect(() => {
    (async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Token ${auth_token}`);
      myHeaders.append('Content-Type', 'application/json');

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const res = await fetch(
        `https://store.nextleveltechapp.com/admin-api/v1/shop-users/${props.params
        }/money-spent-bonuses/`,
        requestOptions,
      )
        .then(response => response.json())
        .then(result => {
          return result;
        })
        .catch(error => {
          console.log('error', error);
          return null;
        });

      if (res !== null) {
        // setSpentBonusData(res.results);

        let filterData = res.results && res.results.map(e => {
          let myDate = moment(e.created_at).format('YYYY-MM-DD');
          e.created_at = myDate;

          return e;
        });

        let resData = [];
        if (res.results && res.results.length > 0) {
          resData = res.results.map(e => {
            e.money_spent =
              e.level_awards.length > 0 ? e.level_awards[0].level_name : '';
            e.gift_name = e.discount === null ? e.points : e.discount;
            return e;
          });
        }

        setSpentBonusData(resData);

        // let myDate = moment(e.updated_at).format('YYYY-MM-DD');
      }

      // const users = await fetchUsers();
      // setUsers(users);
    })();
  }, [setSpentBonusData, props]);

  const getuseData = async val => {
    setColumn(val);
  };

  return (
    <ContentWrapper
      breadcrumb={
        'Dashboard / Users / ' +
        userdata?.user?.first_name +
        ' ' +
        userdata?.user?.last_name
      }
      removeShopUser={true}
      hasSearch={false}
      onButtonOneClick={RemoveUser}
      onButtonTwoClick={openModal}>
      <div className="flex flex-col w-full">
        <div
          className="bg-tableHeaderBg"
          style={{
            height: '75px',
            borderRadius: '10px',
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
          }}>
          <div
            style={{ width: '10%', padding: '15px' }}
            className="flex items-center">
            {userdata?.user?.profile?.profile_image !== null ? (
              <img
                src={userdata?.user?.profile?.profile_image}
                className="user-avatar"
                alt="User avatar"
              />
            ) : (
              <img
                src="https://cdn-icons-png.flaticon.com/512/1077/1077063.png"
                className="user-avatar"
                alt="User avatar"
              />
            )}
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>PENDING</h5>
            <p>{userdata?.pending_count === null ? '0' : userdata.pending_count}</p>
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>PURCHASED</h5>
            <p>{userdata?.purchase_count === null ? '0' : userdata.purchase_count}</p>
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>LEVEL</h5>
            <p>{userdata && userdata.selected_level}</p>
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>POINTS</h5>
            <p>{userdata?.points === null ? '0' : userdata.points}</p>
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>$ SPENT</h5>
            <p>{userdata?.total_spent === null ? '0' : userdata.total_spent}</p>
          </div>
          <div style={{ width: '15%', padding: '15px' }}>
            <h5>REFERRAL</h5>
            <p>{userdata?.referral_code}</p>
          </div>
        </div>

        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <h4>User activity</h4>
          <h4>Referral code : {userdata?.referral_code}</h4>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}>
          <form>
            <h2 className='mb-4'>Money Spent</h2>
            <Input
              value={amount}
              onChange={e => setAmount(e.target.value)}
              label="Add Amount"
            />
            <Input
              value={note}
              onChange={e => setNote(e.target.value)}
              label="Add Note"
            />
            <Button label="Save" onClick={MoneySpent} type="submit" />
          </form>
        </Modal>
        <Modal
          isOpen={bonusmodalIsOpen}
          onRequestClose={closebonusModal}
          style={customStyles}>
          <form>
            <h2>Give Bonus</h2>
            <Input
              // work here
              value={moneyspent}
              onChange={e => setMoneySpent(e.target.value)}
              label="Money Spent"
              placeHolderIs={'Please Enter A Number'}
            />
            <Input
              value={discount}
              onChange={e => setDiscount(e.target.value)}
              label="Discount"
              placeHolderIs={'Please Enter A Number'}
            />
            <Input
              value={points}
              type="number"
              onChange={e => setPoint(e.target.value)}
              label="Points"
              placeHolderIs={'Please Enter A Number'}
            />
            <Select
              className="mb-4"
              onChange={giftgetdata}
              options={productoption}
              // value={gift}
              defaultValue={1}
            />
            <Button label="Save" onClick={GiveBouns} type="submit" />
          </form>
        </Modal>
        <div
          style={{
            height: '45px',
            borderRadius: '10px',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'flex' }}>
            <div
              className={columns === '1' ? 'bg-tableHeaderBg p-2' : 'p-2'}
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('1')}>
              Reward points ({rewardTotal})
            </div>
            <div
              className={columns === '2' ? 'bg-tableHeaderBg p-2' : 'p-2'}
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('2')}>
              Gifts ({giftTotal})
            </div>
            <div
              className={
                columns === '3' ? 'bg-tableHeaderBg p-2 ml-1' : 'p-2 ml-1'
              }
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('3')}>
              Loyalty points purchases ({purchaseTotal})
            </div>
            <div
              className={
                columns === '4' ? 'bg-tableHeaderBg p-2 ml-1' : 'p-2 ml-1'
              }
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('4')}>
              Money spent ({moneyTotal})
            </div>
          </div>
          {columns == '4' ? (
            <Button
              type="primary"
              onClick={openModal}
              label="ADD MONEY SPENT"
            />
          ) : (
            <div style={{ display: 'flex' }}>
              {/* <Button
                type="primary"
                onClick={Acceptslected}
                label="ACCEPT SELECTED"
              />
              <Button
                type="secondary"
                onClick={Rejectslected}
                label="REJECT SELECTED"
              /> */}
            </div>
          )}
          {/* Extra bonus for money spent */}
        </div>
        {columns == '1' ? (
          <>
            <Table columns={tablerewardcolumns} data={RewardData} />
            {RewardData && RewardData.length === 0 && !loading ? <div>
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${rewardpreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setRewardCurrentPage(rewardCurrentPage - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${rewardNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setRewardCurrentPage(rewardCurrentPage + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </>
        ) : columns == '2' ? (
          <>
            <Table columns={tablegiftcolumns} data={GiftData} />
            {GiftData && GiftData.length === 0 && !loading ? <div>
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${giftpreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setGiftCurrentPage(giftCurrentPage - 1);
                  }}>
                  Show previous 10 results
                </button>
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${giftNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setGiftCurrentPage(giftCurrentPage + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </>
        ) : columns == '3' ? (
          <>
            <Table columns={tablepurchasecolumns} data={PurchaseData} />
            {PurchaseData && PurchaseData.length === 0 && !loading ? <div>
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${purchasepreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setPurchaseCurrentPage(purchaseCurrentPage - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${purchaseNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setPurchaseCurrentPage(purchaseCurrentPage + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </>
        ) : (
          <div>
            <>
              <Table columns={tablemoneysentcolumns} data={spendData} />{' '}
              {spendData && spendData.length === 0 && !loading ? <div>
                <p className='text-center mt-5'>No data found</p>
              </div> : <div>
                <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                  <button
                    style={{
                      backgroundColor: '#970c24d9',
                      padding: 10,
                      color: '#fff',
                      borderRadius: 5
                    }}
                    className={`text-xs text-center text-wine ${moneypreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                    onClick={() => {
                      setMoneyCurrentPage(moneyCurrentPage - 1);
                    }}>
                    Show previous 10 results
                  </button>

                  <button
                    style={{
                      backgroundColor: '#970c24d9',
                      padding: 10,
                      color: '#fff',
                      borderRadius: 5
                    }}
                    className={`text-xs text-center text-wine ${moneyNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                    onClick={() => setMoneyCurrentPage(moneyCurrentPage + 1)}>
                    Show next 10 results
                  </button>
                </div>
              </div>}
            </>
            {/* <div
              className="mb-2"
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '20px',
              }}>
              <h5>Extra bonus for money spent</h5>
              <Button
                type="primary"
                onClick={openbonusModal}
                label="GIVE BONUS"
              />{' '}
            </div>
            <Table columns={tablemoneysentbounscolumns} data={spendbonusData} /> */}
          </div>
        )}

        {/* <button>
          <div className="bg-tableHeaderBg py-2">
            <p className="text-btnSecondaryText text-xs text-center">
              show next 10 results
            </p>
          </div>
        </button> */}
      </div>
    </ContentWrapper>
  );
};

export default ShopUserDetail;
