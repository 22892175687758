import React from 'react';

const SelectType = ({items, onSelect, selected}) => {
  return (
    <div className="rounded-3xl border border-trueGray p-1 flex justify-between items-center">
      {items.map(itm => (
        <button
          key={itm.value}
          onClick={() => onSelect(itm.value)}
          className={`rounded-3xl ${
            itm.value === selected ? 'bg-mediumGreen' : 'bg-transparent'
          } mx-1`}>
          <p
            className={`uppercase text-xs py-2 px-4 font-bold ${
              itm.value === selected ? 'text-white' : 'text-btnSecondaryText'
            }`}>
            {itm.label}
          </p>
        </button>
      ))}
    </div>
  );
};

export default SelectType;
