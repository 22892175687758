import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {useLocation} from 'react-router-dom';

export const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
  const [error, setError] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
  }, [error, location.pathname]);

  useEffect(() => {
    const authToken =
      localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');
    if (authToken) {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const onLogout = useCallback(() => {
    localStorage.removeItem('nls:auth');
    sessionStorage.removeItem('nls:auth');
    setIsAuthenticated(false);
  }, []);

  const onLogin = useCallback(({token, remember}) => {
    if (remember) {
      localStorage.setItem('nls:auth', token);
    } else {
      sessionStorage.setItem('nls:auth', token);
    }
    setIsAuthenticated(true);
  }, []);

  const onRegister = useCallback(({token}) => {
    sessionStorage.setItem('nls:auth', token);
    setIsAuthenticated(true);
  }, []);

  const memoedValue = useMemo(
    () => ({
      isAuthenticated,
      error,
      loading,
      onLogin,
      onLogout,
      onRegister,
    }),
    [isAuthenticated, error, loading, onLogin, onLogout, onRegister],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
