import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Routes from './routes';

import { AuthProvider } from '@context/useAuth';
import { StoreProvider } from '@context/useStore';
import { SearchContext } from '@context/searchContext'
import { useState } from 'react';
import { getShopUsers } from './apis';


const queryClient = new QueryClient();

const token = localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

function App() {
  const [userResults, setUserResults] = useState()
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const resp = await getShopUsers();
    {
      !loading && setUserResults(resp.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, []);


  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SearchContext.Provider value={{ userResults, setUserResults, loading, setLoading }} >
            <StoreProvider>
              <Routes />
              <Toaster
                position="top-right"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                  error: {
                    style: {
                      background: '#5E0A0A',
                      color: 'white',
                    },
                  },
                }}
              />
            </StoreProvider>
          </SearchContext.Provider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
