import React, { useState, useEffect } from 'react';

import {
  ContentWrapper,
  Card,
  Button,
  Switch,
  Input,
  SelectType,
  Dropdown,
} from '@components';
import { useMutation } from 'react-query';
import Bronze from '@images/bronze.svg';
import Silver from '@images/silver.svg';
import Select from 'react-select';
import Gold from '@images/gold.svg';
import collapsedImage from '../../assets/images/collapsed-item.png';
import expandedImage from '../../assets/images/extended-item.png';
import questionMark from '../../assets/images/question-mark.png';
import {
  awardManage,
  getbonuses,
  delRewardApi,
  statusBonusesApi,
  AddBonusesApi,
  awardSave,
  getProducts,
  PATCHUpdateRewardApi
} from '@apis';
import Modal from 'react-modal';
import axios from 'axios';
import { API_HOST } from '@apis/';
import { map } from 'lodash';
import toast from 'react-hot-toast';

const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const pointexpire = [
  { label: 'Never', value: 'Never' }
];
const PointsInput = ({ value, onChange, placeholder, ...rest }) => {
  return (
    <div className="relative">
      <input
        {...rest}
        value={value}
        onChange={onChange}
        type="number"
        style={{ maxWidth: 100, textAlign: 'center' }}
        className="rounded h-8 px-2 text-sm border bg-trueGray-100 bold-text"
      // placeholder= {value}
      // defaultValue= {5}
      />
      <img
        src={Silver}
        alt="Silver Icon"
        className="h-4 absolute top-2 right-6"
      />
    </div>
  );
};

const PointsSetup = ({ }) => {
  // Declared the options for various levels to be used in the add rewards modal
  const levelOptions = [
    { label: 'Bronze', value: 'bronze' },
    { label: 'Silver', value: 'silver' },
    { label: 'Gold', value: 'gold' },
  ];
  // var levelOptionsDict = []

  // const levelOptionsDict = [{label: 'Eweii', value:'astata'}]

  // This function handles the level option selected
  // const handleTypeSelection = item => {
  //   item.preventDefault()
  //   setSelectedLevelOption(item);
  //   console.log("Eweiii");
  // };

  const [state, setState] = useState({});
  const [tableview, setTableView] = useState(false);
  const [isEditedView, setEditedView] = useState(false);
  const [awardData, setawardData] = useState([]);
  const [bonusData, setbonusData] = useState([]);
  // const [productsData, setproductsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [unfilteredProducts, setUnfilteredProducts] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [storeLevels, setStoreLevels] = useState([]); //This variable lists all the custom leves a store has
  const [storeLevelsLoading, setStoreLevelsLoading] = useState(true);

  const [level, setLevel] = useState('');
  const [selectedLevelOption, setSelectedLevelOption] = useState();
  const [awardType, setAwardType] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [points, setPoints] = useState(0);
  const [levelOptionsDictLoading, setLevelOptionDictLoading] = useState(true);
  const [levelOptionsDict, setLevelOptionsDict] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  // Controls the modal for creating a new reward
  const openModal = () => {
    setIsOpen(true);
    setRefresh(!refresh);
    // getStoreLevels()
  };
  const closeModal = async () => {
    setIsOpen(false);
  };
  //End of modal controller
  const TableView = (val, key) => {
    setTableView(val.target.checked);
    setSelectedRow(key);
  };

  var newAwardData = {
    award_type: awardType,
    enabled: enabled,
    discount: discount,
    points: points,
    level: selectedLevelOption,
  };
  // Add a new custom Level Award
  var createAwardAxiosConfig = {
    url: API_HOST + '/admin-api/v1/level-awards/',
    method: 'post',
    body: newAwardData,
    headers: {
      'content-type': 'application/json',
      Authorization: `Token ${auth_token}`,
    },
    data: newAwardData,
  };
  const addAward = e => {
    fetchData();
    e.preventDefault();
    axios(createAwardAxiosConfig)
      .then(toast.success('New award created'))
      .then(setIsOpen(!modalIsOpen))
      .then(setRefresh(!refresh));
    // axios.post(API_HOST + '/admin-api/v1/level-awards/',
    //   {
    //     headers : {
    //       "Authorization" : `Token ${token}`,
    //       'content-type': 'application/json',
    //     },
    //     data: {
    //       // level: level,
    //       award_type: awardType,
    //       enabled: enabled,
    //       discount: discount,
    //       points: points,
    //       level: selectedLevelOption,
    //       // gift: 's',
    //     }
    //   }
    //   )
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  };

  const addMore = async () => {
    var bonuses = bonusData;
    const newBonus = await AddBonusesApi({
      amount: 10,
      enabled: false,
      discount: null,
      points: null,
      gift: null,
    });
    const getbonusesresult = await getbonuses();
    setbonusData(getbonusesresult);
    setState(s => ({
      ...s,
      bonusData: getbonusesresult,
    }));
  };

  const fetchData = async () => {
    const awardManageresult = await awardManage();
    const getbonusesresult = await getbonuses();
    const getproductsresult = await getProducts();
    setawardData(awardManageresult);
    setbonusData(getbonusesresult);
    setUnfilteredProducts(getproductsresult.results);

    // Filter poducts to return only gifts

    setState(s => ({
      ...s,
      eligibility_points1: awardManageresult[0]?.eligibility_points
        ? awardManageresult[0]?.eligibility_points
        : 0,
      eligibility_points2: awardManageresult[1]?.eligibility_points
        ? awardManageresult[1]?.eligibility_points
        : 0,
      eligibility_points3: awardManageresult[2]?.eligibility_points
        ? awardManageresult[2]?.eligibility_points
        : 0,
      discount1: awardManageresult[0]?.discount
        ? awardManageresult[0]?.discount
        : 0,
      discount2: awardManageresult[1]?.discount
        ? awardManageresult[1]?.discount
        : 0,
      discount3: awardManageresult[2]?.discount
        ? awardManageresult[2]?.discount
        : 0,
      gift1: awardManageresult[0]?.gift ? awardManageresult[0]?.gift : null,
      gift2: awardManageresult[1]?.gift ? awardManageresult[1]?.gift : null,
      gift3: awardManageresult[2]?.gift ? awardManageresult[2]?.gift : null,
      // giftDisplay1 : awardManageresult[0].gift_display ? awardManageresult : null,
      // giftDisplay2 : awardManageresult[1].gift_display ? awardManageresult : null,
      // giftDisplay3 : awardManageresult[2].gift_display ? awardManageresult : null,

      giftDisplay1: awardManageresult[0]?.gift_display
        ? awardManageresult[0]?.gift_display.name
        : null,
      giftDisplay2: awardManageresult[1]?.gift_display
        ? awardManageresult[1]?.gift_display.name
        : null,
      giftDisplay3: awardManageresult[2]?.gift_display
        ? awardManageresult[2]?.gift_display.name
        : null,
      bonusData: getbonusesresult,
      productsData: getproductsresult.results.filter(function (item) {
        return item.is_gift;
      }),
    }));
    setLoadingData(false);
  };

  const getStoreLevels = async () => {
    await axios
      .get(API_HOST + '/admin-api/v1/get-store-levels', {
        headers: {
          Authorization: `Token ${auth_token}`,
          'content-type': 'application/json',
        },
      })
      .then(res => {
        setStoreLevels(res.data);
        setStoreLevelsLoading(false);
        let aList = [{ label: 'SELECT LEVEL', value: '-1' }];
        !storeLevelsLoading &&
          storeLevels.map(level => {
            aList.push({ label: level.level_display, value: level.id });
          });
        setLevelOptionsDict(aList);
        setLevelOptionDictLoading(false);
      })
      .catch(err => console.log(err));
  };
  // This effect listens for the list of storeLevels to be fetched from the backend.
  // useEffect(()=>{
  //   let temporaryDict = []
  //   !storeLevelsLoading && storeLevels.map((level)=>{
  //     temporaryDict.push({label: level.level_display, value: level.id})
  //   })
  //   setLevelOptionsDict(temporaryDict)
  //   console.log(levelOptionsDict)
  //   setLevelOptionDictLoading(false)
  // }, [storeLevels])

  const DelBonuses = useMutation(params => {
    delRewardApi(params);
    toast.success(`Rewards removed successfully`)
    fetchData();
  });

  const RemoveReward = async id => {
    await DelBonuses.mutateAsync(id);
    fetchData();
    setTimeout(() => {
      setRefresh(!refresh)
    }, 1200);
  };

  useEffect(() => {
    fetchData();
    getStoreLevels();
    getStoreLevels();
  }, [refresh]);
  const [selectedType, setSelectedType] = useState(pointexpire[0].value);

  const itemCollapser = key => {
    if (selectedRow == key) {
      return (
        <img
          onClick={value => TableView(value, key)}
          src={expandedImage}
          alt="Silver Icon"
          className="collapser-image"
        />
      );
    } else {
      return (
        <img
          onClick={value => TableView(value, key)}
          src={collapsedImage}
          alt="Silver Icon"
          className="collapser-image"
        />
      );
    }
  };

  const GiftApiCall = async (awardId, productID) => {
    try {
      await axios
        .get(
          API_HOST + `/admin-api/v1/level-award/${awardId}/gift/${productID}`,
          {
            headers: {
              Authorization: `Token ${auth_token}`,
              'content-type': 'application/json',
            },
          },
        )
        .then(res => {
          console.log('response is gift', res);
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log('Error in GiftApiCall');
    }
  };

  // console.log("This si the state",state)

  // console.log("here we go", awardData[0])
  // var gbese = awardData
  // console.log(gbese[1])
  // // gbese[0].gift1 = 7
  // // setawardData(gbese)
  // awardData[0].gift = 7

  const SubmitProductup = useMutation(params => PATCHUpdateRewardApi(params));
  const submit = async (id, data) => {
    var enableStatus = false;
    if (data.enabled) {
      enableStatus = false;
    } else {
      enableStatus = true;
    }
    await SubmitProductup.mutateAsync({
      id: id,
      award_type: data.award_type,
      discount: data.discount,
      points: data.points,
      level: data.level,
      enabled: enableStatus,
    });
    fetchData();
  };
  const Enabled = async (id, data) => {
    submit(id, data);
  };

  return (
    <ContentWrapper
      breadcrumb={['Dashboard', 'Loyalty Program Setup']}
      onButtonOneClick={() => {
        for (let i = 0; i < awardData.length; i++) {
          awardSave(awardData[i]);
        }
      }}
      onButtonTwoClick={() => window.location.reload()}
      hasSearch={false}
      isEditedView={isEditedView}>
      <div className="grid gap-4 grid-cols-2 md:grid-cols-3">
        <Card
          title="Bronze"
          icon={<img src={Bronze} alt="Bronze Icon" />}
          rightContent={
            <PointsInput
              value={state.eligibility_points1}
              onChange={event => {
                var value = parseInt(event.target.value);
                var awards = awardData;
                awards[0].eligibility_points = value;
                setState(s => ({
                  ...s,
                  eligibility_points1: awardData[0]?.eligibility_points,
                  eligibility_points2: awardData[1]?.eligibility_points,
                  eligibility_points3: awardData[2]?.eligibility_points,
                  discount1: awardData[0]?.discount,
                  discount2: awardData[1]?.discount,
                  discount3: awardData[2]?.discount,
                  gift1: 9,
                  gift2: awardData[1].gift,
                  gift3: awardData[2].gift,
                }));
                setawardData(awards);
                setEditedView(true);
              }}
            />
          }>
          <div>
            <p className="gray-text">AWARDS FOR THIS LEVEL</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <Switch enabled={state.discount1 ? true : false}/> */}
              Discount
            </div>
            <div>
              <Input
                placeholder={'-'}
                value={state.discount1}
                onChange={event => {
                  var value = event.target.value;
                  var awards = awardData;
                  awards[0].discount = value;
                  setawardData(awards);

                  setState(s => ({
                    ...s,
                    eligibility_points1: awardData[0]?.eligibility_points,
                    eligibility_points2: awardData[1]?.eligibility_points,
                    eligibility_points3: awardData[2]?.eligibility_points,
                    discount1: awardData[0]?.discount,
                    discount2: awardData[1]?.discount,
                    discount3: awardData[2]?.discount,
                    gift1: awardData[0].gift,
                    gift2: awardData[1].gift,
                    gift3: awardData[2].gift,
                  }));
                  setEditedView(true);
                }}
                white
              />
            </div>
          </div>
          {/* <div style={{display:"flex",float:"left"}}><Switch enabled={awardData[0]?.gift ? true : false}  onChange={() => { 
            var awards = awardData; 
            awards[0].gift = !awardData[0]?.gift; 
            setawardData(awards);
            console.log(awardData);
            setEditedView(true);
          }}/>Gift</div><br/><br/>        */}

          {/* <div>
            <p>Item name lorem ipsum dolor</p>
          </div> */}
          {/* <div>
            <p className='tabbed-link'>Change</p>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{}}>
              <h4>Gift: </h4>
            </div>
            <div>
              {state.giftDisplay1 ? (
                <h4>{state.giftDisplay1}</h4>
              ) : (
                <h4>No gift</h4>
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              {!state.gift1 == null ? (
                <h4>Select gift </h4>
              ) : (
                <h4>Change gift </h4>
              )}
            </div>
            <div style={{ width: '50%' }}>
              {!loadingData && (
                <Dropdown
                  items={state.productsData}
                  value={state.gift1}
                  placeholder={'-'}
                  callback={item => {
                    var awards = awardData;
                    awards[0].gift = item;
                    setawardData(awards);
                    setEditedView(true);
                  }}
                  white
                />
              )}
            </div>
          </div>

          {/* <Dropdown 
              items={state.productsData}  placeholder={"-"} callback={(item)=>{
              setState();
              }} white
            
          /> */}
        </Card>
        <Card
          title="Silver"
          icon={<img src={Silver} alt="Silver Icon" />}
          rightContent={
            <PointsInput
              value={state.eligibility_points2}
              onChange={event => {
                var value = event.target.value;
                var awards = awardData;
                awards[1].eligibility_points = value;
                setState(s => ({
                  ...s,
                  eligibility_points1: awardData[0]?.eligibility_points,
                  eligibility_points2: awardData[1]?.eligibility_points,
                  eligibility_points3: awardData[2]?.eligibility_points,
                  discount1: awardData[0]?.discount,
                  discount2: awardData[1]?.discount,
                  discount3: awardData[2]?.discount,
                  gift1: awardData[0].gift,
                  gift2: awardData[1].gift,
                  gift3: awardData[2].gift,
                }));
                setawardData(awards);
                setEditedView(true);
              }}
            />
          }>
          <div>
            <p className="gray-text">AWARDS FOR THIS LEVEL</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <Switch enabled={state.discount2 ? true : false}/> */}
              Discount
            </div>
            <div>
              <Input
                placeholder={'-'}
                value={state.discount2}
                onChange={event => {
                  var value = event.target.value;
                  var awards = awardData;
                  awards[1].discount = value;
                  setawardData(awards);
                  setState(s => ({
                    ...s,
                    eligibility_points1: awardData[0]?.eligibility_points,
                    eligibility_points2: awardData[1]?.eligibility_points,
                    eligibility_points3: awardData[2]?.eligibility_points,
                    discount1: awardData[0]?.discount,
                    discount2: awardData[1]?.discount,
                    discount3: awardData[2]?.discount,
                  }));
                  setEditedView(true);
                }}
                white
              />
            </div>
          </div>
          {/* <div style={{display:"flex",float:"left"}}><Switch enabled={awardData[1]?.gift ? true : false} onChange={() => { 
            var awards = awardData; 
            awards[1].gift = !awardData[1]?.gift; 
            setawardData(awards);
            console.log(awardData);
            setEditedView(true);
          }}/>Gift</div><br/><br/>        */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{}}>
              <h4>Gift: </h4>
            </div>
            <div>
              {state.giftDisplay2 ? (
                <h4>{state.giftDisplay2}</h4>
              ) : (
                <h4>No gift</h4>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              {!state.gift2 == null ? (
                <h4>Select gift </h4>
              ) : (
                <h4>Change gift </h4>
              )}
            </div>
            <div style={{ width: '50%' }}>
              {!loadingData && (
                <Dropdown
                  items={state.productsData}
                  value={state.gift2}
                  placeholder={'-'}
                  callback={item => {
                    var awards = awardData;
                    awards[1].gift = item;
                    setawardData(awards);
                    setEditedView(true);
                  }}
                  white
                />
              )}
            </div>
          </div>

          {/* <div style={{textAlign:"center"}}> 
            <p className='gray-text'>--------- &nbsp;&nbsp; Gift is inactive  &nbsp;&nbsp; ---------</p>
          </div> */}
        </Card>
        <Card
          title="Gold"
          icon={<img src={Gold} alt="Gold Icon" />}
          rightContent={
            <PointsInput
              value={state.eligibility_points3}
              // placeholder="099"
              onChange={event => {
                var value = event.target.value;
                var awards = awardData;
                awards[2].eligibility_points = value;
                setState(s => ({
                  ...s,
                  eligibility_points1: awardData[0]?.eligibility_points,
                  eligibility_points2: awardData[1]?.eligibility_points,
                  eligibility_points3: awardData[2]?.eligibility_points,
                  discount1: awardData[0]?.discount,
                  discount2: awardData[1]?.discount,
                  discount3: awardData[2]?.discount,
                }));
                setawardData(awards);
                setEditedView(true);
              }}
            />
          }>
          <div>
            <p className="gray-text">AWARDS FOR THIS LEVEL</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <Switch enabled={state.discount3 ? true : false}/> */}
              Discount
            </div>

            <div>
              <Input
                value={state.discount3}
                onChange={event => {
                  var value = event.target.value;
                  var awards = awardData;
                  awards[2].discount = value;
                  setawardData(awards);
                  setState(s => ({
                    ...s,
                    eligibility_points1: awardData[0]?.eligibility_points,
                    eligibility_points2: awardData[1]?.eligibility_points,
                    eligibility_points3: awardData[2]?.eligibility_points,
                    discount1: awardData[0]?.discount,
                    discount2: awardData[1]?.discount,
                    discount3: awardData[2]?.discount,
                  }));
                  setEditedView(true);
                }}
                white
              />
            </div>
          </div>
          {/* <div style={{display:"flex",float:"left"}}><Switch enabled={awardData[2]?.gift ? true : false} onChange={() => { 
            var awards = awardData; 
            awards[2].gift = !awardData[2]?.gift; 
            setawardData(awards);
            console.log(awardData);
            setEditedView(true);
          }}/>Gift</div><br/><br/>        */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{}}>
              <h4>Gift: </h4>
            </div>
            <div>
              {state.giftDisplay3 ? (
                <h4>{state.giftDisplay3}</h4>
              ) : (
                <h4>No gift</h4>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              {!state.gift3 == null ? (
                <h4>Select gift </h4>
              ) : (
                <h4>Change gift </h4>
              )}
            </div>
            <div style={{ width: '50%' }}>
              {!loadingData && (
                <Dropdown
                  items={state.productsData}
                  value={state.gift3}
                  placeholder={'-'}
                  callback={item => {
                    var awards = awardData;
                    awards[2].gift = item;
                    setawardData(awards);
                    setEditedView(true);
                  }}
                  white
                />
              )}
            </div>
          </div>

          {/* <div>
            <p>Item name lorem ipsum dolor</p>
          </div>
          <div>
            <p className='tabbed-link'>Change</p>
          </div> */}
        </Card>
      </div>
      <div style={{ marginTop: '50px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p className="font-bold mb-4">Awards management</p>
            <Button
              type="primary"
              label="ADD REWARD"
              onClick={() => openModal()}
            />
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}>
            <form>
              <h2>Add Reward</h2>
              <Input
                value={awardType}
                type="text"
                onChange={e => setAwardType(e.target.value)}
                label="Award Type"
              />
              <Input
                value={discount}
                type="number"
                onChange={e => setDiscount(e.target.value)}
                label="Discount"
              />
              <Input
                value={points}
                type="number"
                onChange={e => setPoints(e.target.value)}
                label="Points"
              />

              {!levelOptionsDictLoading ? (
                <>
                  {/* <p>{levelOptionsDict[0].value}</p> */}
                  <label className="text-black text-xs uppercase font-light">
                    Level
                  </label>
                  <div className="py-1">
                    <select
                      className=" w-full text-black uppercase font-light p-1"
                      value={selectedLevelOption}
                      onChange={e => setSelectedLevelOption(e.target.value)}>
                      {levelOptionsDict.map(option => (
                        <option value={option.value} className="">
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : (
                <div>Loading ...</div>
              )}
              <div className="checkbox-row">
                <div>
                  <label className="checkbox-label">Enabled</label>
                </div>
                <div>
                  <Input
                    type="checkbox"
                    value={enabled}
                    onChange={e => setEnabled(e.target.value)}
                  />
                </div>
              </div>
              <Button label="Save" onClick={addAward} type="submit" />
            </form>
          </Modal>

          {/* <div style={{ display: 'flex' }}>
            <p style={{ marginRight: 10, marginTop: 5 }}>Points expire</p>
            <img
              src={questionMark}
              alt="?"
              style={{
                width: 15,
                height: 15,
                marginTop: 11,
                marginRight: 10,
                cursor: 'pointer',
              }}
              title="Help text here"
            />
            <Select options={pointexpire} />
          </div> */}
        </div>

        {awardData?.map((item, key) => (
          <div style={{ marginTop: '20px' }}>
            <table style={{ width: '100%', marginTop: '20px' }}>
              <thead
                className="bg-gray-100"
                style={{ textAlign: 'center', height: '50px' }}>
                <td style={{ width: '45px' }}>{itemCollapser(key)}</td>
                <td style={{ width: '17%' }}>{item.level_display}</td>
                <td style={{ width: '20%' }}>STATUS</td>
                <td style={{ width: '15%' }}>DISCOUNT</td>
                <td style={{ width: '15%' }}>POINTS</td>
                <td style={{ width: '20%' }}>GIFT</td>
                <td style={{ width: '20%' }}>ACTION</td>
              </thead>
              {selectedRow == key ? (
                <tbody className="content">
                  {item?.awards?.map(itemaward => (
                    <tr className="text-center">
                      <td style={{ width: '45px' }} />
                      <td style={{ textAlign: 'left', width: '17%' }}>
                        {itemaward.award_type}
                      </td>
                      <td style={{ textAlign: 'center', width: '20%' }}>
                        <Switch
                          onChange={() => Enabled(itemaward.id, itemaward)}
                          enabled={itemaward && itemaward.enabled}
                        />
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          width: '20%',
                          paddingLeft: 25,
                          paddingRight: 25,
                        }}>
                        <Input
                          value={itemaward.discount}
                          placeholder={'-'}
                          white
                        />
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          width: '20%',
                          paddingLeft: 25,
                          paddingRight: 25,
                        }}>
                        <Input
                          value={itemaward.points}
                          placeholder={'-'}
                          white
                        />
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          width: '20%',
                          paddingLeft: 25,
                          paddingRight: 25,
                        }}>
                        <Dropdown
                          items={state.productsData}
                          value={itemaward?.gift_display?.id}
                          placeholder={'-'}
                          white
                          callback={item => {
                            //itemaward.id
                            let tmpData = awardData;

                            GiftApiCall(itemaward.id, item);

                            let gift_name = state.productsData.filter(
                              e => e.id === item,
                            );

                            tmpData.map(e => {
                              if (e.awards.length > 0) {
                                e.awards.map(i => {
                                  if (i.id === itemaward.id) {
                                    i.gift_display = gift_name[0].name;
                                    // i.
                                  }

                                  return i;
                                });
                              }
                            });

                            setawardData(tmpData);
                            setEditedView(true);
                          }}
                        />
                      </td>
                      <td style={{ textAlign: 'left', width: '17%' }}>
                        <div className="flex items-center">
                          <div className="px-4">
                            <p className="text-left">
                              <p
                                className="tabbed-link"
                                style={{ marginLeft: -15 }}
                                onClick={() => RemoveReward(itemaward.id)}
                              >
                                Remove
                              </p>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div />
              )}
            </table>
          </div>
        ))}

        {/* <div style={{marginTop: '20px'}}>
          Extra bonus based on money spent
          <table style={{width: '100%', marginTop: '20px'}}>
            <thead
              className="bg-gray-100"
              style={{textAlign: 'center', height: '50px'}}>
              <td>AMOUNT</td>
              <td>STATUS</td>
              <td>DISCOUNT</td>
              <td>POINTS</td>
              <td>GIFT</td>
              <td>ACTION</td>
            </thead>

            <tbody className="content">
              {state.bonusData?.map((item, key) => (
                <tr
                  style={{marginTop: 0, marginBottom: 0}}
                  key={key}
                  className="text-center">
                  <td
                    style={{
                      textAlign: 'center',
                      width: '20%',
                      paddingLeft: 25,
                      paddingRight: 25,
                      marginTop: 0,
                      marginBottom: 0,
                    }}>
                    <Input value={item.amount | 0} white placeholder={'-'} />
                  </td>
                  <td>
                    <Switch
                      enabled={item.enabled}
                      onChange={() => (item.enabled = !item.enabled)}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '20%',
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}>
                    <Input value={item.discount} white placeholder={'-'} />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '20%',
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}>
                    <Input value={item.points} white placeholder={'-'} />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '20%',
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}>
                    <Dropdown
                      items={state.productsData}
                      value={item.gift_display?.name}
                      placeholder={'-'}
                      callback={item => {
                        console.log('item >>>>> +++ >>', item);
                      }}
                      white
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '20%',
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}>
                    <p
                      className="tabbed-link"
                      onClick={() => RemoveBonus(item.id)}>
                      Remove
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{marginTop: 15}}>
            <p className="tabbed-link" onClick={() => addMore()}>
              Add more
            </p>
          </div>
        </div> */}
      </div>
    </ContentWrapper>
  );
};

export default PointsSetup;
