import React, { useState, useEffect } from 'react';
import open from '../../assets/images/open.png';
import deleteI from '../../assets/images/delete.png';

const Dropdown = ({
  label,
  dark,
  white,
  maxWidth,
  marginRight,
  items,
  callback,
  value,
  ...rest
}) => {
  let new_item = [items];

  const [state, setState] = useState({ currentItems: items });

  useState(() => {
    if (value != undefined || value != null) {
      let filterItem = items.filter(e => e.id === value);

      if (filterItem.length > 0) {
        setState(s => ({
          ...s,
          value: filterItem[0].name,
        }));
      }
    }
  }, [value, setState]);

  const openFunction = () => {
    setState(s => ({
      ...s,
      openList: !state.openList,
    }));
  };

  return (
    <div className="flex flex-col flex-grow">
      <div
        style={{
          overflowY: 'auto',
          position: 'absolute',
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: '#E1E1E1',
          borderRadius: 5,
          textAlign: 'left',
          height: 200,
          width: 200,
          display: state.openList ? 'block' : 'none',
        }}>
        <div
          style={{
            padding: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#E1E1E1',
            fontWeight: 'bold',
          }}>
          <input
            style={{ width: '80%' }}
            placeholder="filter"
            onChange={event => {
              var value = event.target.value;
              var itens = [];
              for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.name?.toLowerCase().startsWith(value?.toLowerCase())) {
                  itens.push(item);
                }
              }
              setState(s => ({
                ...s,
                currentItems: itens,
              }));
            }}
          />
          <img
            onClick={() => {
              openFunction();
              setState(s => ({
                ...s,
                value: '', //item.name
              }));
            }}
            src={deleteI}
            alt="?"
            style={{
              width: 15,
              height: 15,
              cursor: 'pointer',
              marginTop: 5,
              float: 'right',
            }}
            title="Help text here"
          />
        </div>
        {state.currentItems &&
          state.currentItems.map((item, key) => (
            <div
              key={key}
              onClick={() => {
                callback(item.id);
                openFunction();
                setState(s => ({
                  ...s,
                  value: item.name,
                }));
              }}
              style={{
                padding: 10,
                borderBottomWidth: 1,
                borderBottomColor: '#E1E1E1',
              }}>
              {item.name}
            </div>
          ))}
      </div>
      <p
        className={`${dark ? 'text-white' : 'text-black'
          } text-xs uppercase font-light`}>
        {label}
      </p>
      <div
        {...rest}
        className={`${dark ? 'bg-trueGray-100' : 'bg-transparent'
          } rounded h-8 my-2 px-2 text-sm ${dark ? 'border-0' : 'border'}`}
        style={{
          backgroundColor: white ? 'white' : 'transparent',
          maxWidth: maxWidth ? maxWidth : undefined,
          marginRight: marginRight,
          paddingTop: 5,
          textAlign: 'left',
        }}>
        {state.value ? state.value : ''}
        <img
          onClick={() => openFunction()}
          src={open}
          alt="?"
          style={{
            width: 15,
            height: 15,
            cursor: 'pointer',
            marginTop: 2,
            float: 'right',
          }}
          title="Help text here"
        />
      </div>
    </div>
  );
};

export default Dropdown;
