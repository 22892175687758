import React, {useState} from 'react';

const Switch = ({label, dark, enabled, onChange, ...rest}) => {
  const [enabled1, setEnabled] = useState(enabled);
  return (
    <div onClick={() => { setEnabled(!enabled1); if (onChange) onChange();}} className="w-full h-full flex flex-col justify-center items-center">
      <div className="flex justify-center items-center">
        <div
          className={`w-14 h-7 flex items-center rounded-full mx-3 px-1 white-background gray-border`}>
          <div className={`${
            enabled1 ? 'selected-trigger translate-x-7' : 'unselected-trigger'
          } w-5 h-5 rounded-full shadow-md transform`}></div>
        </div>
      </div>
    </div>
  );
};

export default Switch;
