import axios from 'axios';
import toast from 'react-hot-toast';

export const API_HOST = 'https://store.nextleveltechapp.com';
//export const API_HOST = 'http://localhost:8000';

// deploy check

const doFetch = async (url, params, hasFile = false) => {
  const token =
    localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');
  let headers = {};
  if (hasFile) {
    headers = {};
  } else {
    headers = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
    };
  }

  if (token && !url.includes('sign-in') && !url.includes('sign-up')) {
    headers.Authorization = `Token ${token}`;
  }


  return fetch(API_HOST + url, {
    headers,
    ...params,
  }).then(async response => {
    if (response.ok) {
      let data = await response.json();
      return data;
      //return await response.json();
    } else {
      const errorMessage = await response.text();
      const error = await handleError(JSON.parse(errorMessage));
      try {
        if (error.includes('Invalid token.')) {
          return;
        }
      } catch (e) {
        console.log(e);
      }

      if (url === '/api/v1/rest-auth/login/') {
        return Promise.reject(error);
      } else {
        return 'Errors';
      }
    }
  });
};

export const handleError = error => {
  const errorKeys = Object.keys(error);
  if (errorKeys.includes('non_field_errors')) {
    return error.non_field_errors && error.non_field_errors[0];
  }
  if (errorKeys.includes('email')) {
    return error.email[0] || error.email.email[0];
  }
  const firstKey = errorKeys[0];
  return `${error[firstKey]}`;
};

export const loginApi = async params => {
  let data = await doFetch('/api/v1/rest-auth/login/', {
    method: 'POST',
    body: JSON.stringify(params, params),
  });

  return data;
};

export const loginApiNew = async params => {
  let data = await doFetch('/api/v1/rest-auth/login/', {
    method: 'POST',
    body: JSON.stringify(params),
  });


  return false;
};

export const registerApi = params => {
  return doFetch('/admin-api/v1/auth/sign-up/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

export const forgotPasswordApi = params => {
  return doFetch('/admin-api/v1/auth/forgot-password/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

export const resetPasswordApi = params => {
  return doFetch('/admin-api/v1/auth/reset-password/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
export const addProductApi = params => {
  return doFetch('/admin-api/v1/products/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
export const ImgUploadtApi = params => {
  return doFetch(
    '/admin-api/v1/products/' + params.id + '/add-image/',
    {
      method: 'POST',
      body: params.formdata,
    },
    true,
  );
};

export const Acceptapi = params => {
  return doFetch('/admin-api/v1/earned-rewards/' + params.id + '/', {
    method: 'PATCH',
    body: JSON.stringify({ accepted: params.accept }),
  });
};

export const ProductPurchaseNew = (id, params) => {

  return doFetch(`/admin-api/v1/product-purchases/${id}/status-change/`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
    .then(() => {
      toast.success(`Purchase ${params.accepted ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error awrad save', e);
    });
};

export const AcceptPurchaseapi = params => {
  return doFetch('/admin-api/v1/product-purchases/' + params.id + '/', {
    method: 'PATCH',
    body: JSON.stringify({ accepted: params.accept }),
  })
    .then(() => {
      toast.success(`Loyalty points ${params.accept ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error awrad save', e);
    });
};

export const UpdateProductApi = params => {
  return doFetch('/admin-api/v1/products/' + params.id + '/', {
    method: 'PUT',
    body: JSON.stringify({
      name: params.name,
      points_cost: params.points_cost,
      available_stock: params.available_stock,
      description: params.description,
      enabled: params.enabled,
      is_gift: params.is_gift,
    }),
  })
    .then(() => {
      toast.success(`Product updated successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const PATCHUpdateProductApi = params => {
  return doFetch('/admin-api/v1/products/' + params.id + '/', {
    method: 'PATCH',
    body: JSON.stringify({
      name: params.name,
      points_cost: params.points_cost,
      available_stock: params.available_stock,
      description: params.description,
      enabled: params.enabled,
    }),
  })
    .then(() => {
      toast.success(`Product ${params.enabled ? 'enabled' : 'disabled'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const PATCHUpdateRewardApi = params => {
  return doFetch('/admin-api/v1/level-awards/' + params.id + '/', {
    method: 'PATCH',
    body: JSON.stringify({
      award_type: params.award_type,
      discount: params.discount,
      points: params.points,
      level: params.level,
      enabled: params.enabled,
    }),
  });
};
export const addMountSpentApi = params => {
  return doFetch('/admin-api/v1/shop-users/' + params.id + '/money-spents/', {
    method: 'POST',
    body: JSON.stringify({ amount: params.amount, note: params.note }),
  });
};
export const giveBonusesApi = params => {
  return doFetch(
    '/admin-api/v1/shop-users/' + params.id + '/money-spent-bonuses/',
    {
      method: 'POST',
      body: JSON.stringify({
        money_spent: params.money_spent,
        discount: params.discount,
        points: params.points,
        reward_type: params.gift,
      }),
    },
  );
};
export const delProductApi = params => {
  return doFetch('/admin-api/v1/products/' + params + '/', {
    method: 'DELETE',
  })
    .then(() => {
      toast.success(`Product removed successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const delShopUserApi = params => {

  return doFetch('/admin-api/v1/shop-users/' + params + '/', {
    method: 'DELETE',
  });
};
export const delBounusesApi = params => {
  return doFetch('/admin-api/v1/bonuses/' + params + '/', {
    method: 'DELETE',
  })
    .then(() => {
      toast.success(`Bonus removed successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const delRewardApi = params => {
  return doFetch('/admin-api/v1/level-awards/' + params + '/', {
    method: 'DELETE',
  })
    .then(() => {
      toast.success(`Rewards removed successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const getStoreApi = () => {
  return doFetch('/admin-api/v1/stores/self/');
};

export const updateStoreApi = params => {
  const {
    name,
    logo,
    points_expire,
    money_spent_bonus,
    automate_earned_rewards,
  } = params;

  var FormData = require('form-data');

  var data = new FormData();
  if (name) data.append('name', name);
  if (logo) data.append('logo', logo);
  if (points_expire) data.append('points_expire', points_expire);
  if (money_spent_bonus) data.append('money_spent_bonus', money_spent_bonus);
  if (automate_earned_rewards)
    data.append('automate_earned_rewards', automate_earned_rewards);

  const token =
    localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

  var config = {
    method: 'patch',
    url: API_HOST + '/admin-api/v1/stores/self/',
    headers: {
      Authorization: 'token ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const toggleUserActiveStatus = id => {
  return doFetch('/admin-api/v1/toggle-user-active-status/', {
    method: 'POST',
    body: JSON.stringify({
      id: id,
    }),
  });
};

export const searchUsers = query => {
  return doFetch(`/admin-api/v1/shop-users/?q=${query}`);
};

export const getShopUsers = () => {
  return doFetch('/admin-api/v1/shop-users/');
};

export const findUsers = () => {
  return doFetch('/admin-api/v1/shop-users/');
};

export const AddBonusesApi = params => {
  return doFetch('/admin-api/v1/bonuses/', {
    method: 'POST',
    body: JSON.stringify({
      amount: params.amount,
      enabled: params.enabled,
      discount: params.discount,
      points: params.points,
      gift: params.gift,
    }),
  });
};
export const statusBonusesApi = params => {
  return doFetch('/admin-api/v1/bonuses/' + params.id + '/', {
    method: 'PATCH',
    body: JSON.stringify({
      amount: params.amount,
      enabled: params.enabled,
      discount: params.discount,
      points: params.points,
      gift: params.gift,
    }),
  });
};
export const getbonuses = () => {
  return doFetch('/admin-api/v1/bonuses/');
};
export const getreedemed = () => {
  return doFetch('/admin-api/v1/stores/stats-summary/');
};
export const awardManage = () => {
  return doFetch('/admin-api/v1/loyalty-levels/');
};
export const awardSave = params => {
  return doFetch('/admin-api/v1/loyalty-levels/' + params.id + '/', {
    method: 'PUT',
    body: JSON.stringify(params),
  })
    .then(() => {
      toast.dismiss()
      toast.success('Successfully Updated Value')
      awardManage()
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error awrad save', e);
    });
};
export const getReward = () => {
  // return doFetch('/admin-api/v1/earned-rewards/');
  return doFetch('/admin-api/v1/rewards/9');
};

export const getRewardNewApi = async (params, status) => {

  let data = await doFetch(`/admin-api/v1/rewards/?page=${params && params.page}&page_size=${params && params.page_size}&status=${status}`);

  return data;
};

export const getGiftData = async (params, status) => {

  let data = await doFetch(`/admin-api/v1/gifts/?page=${params && params.page}&page_size=${params && params.page_size}&status=${status}`);

  return data;
};

export const getLatestTransaction = async () => {

  let data = await doFetch('/api/v1/transactions/');

  return data;
};

export const changeRewardStatus = async (id, params) => {
  return doFetch(`/admin-api/v1/rewards/${id}/reward_status_change/`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
    .then(() => {
      toast.success(`Reward ${params.status === 'accepted' ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error awrad save', e);
    });
};

export const changeGiftStatus = async (id, params) => {
  return doFetch(`/admin-api/v1/gifts/${id}/gift_status_change/`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
    .then(() => {
      toast.success(`Gift ${params.status === 'accepted' ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error awrad save', e);
    });
};

export const getPurchases = (params, status) => {
  return doFetch(`/admin-api/v1/product-purchases/?page=${params && params.page}&page_size=${params && params.page_size}&status=${status}`);
};
export const getShopUsersDetail = params => {
  return doFetch('/admin-api/v1/shop-users/' + params, {
    method: 'GET',
  });
};
export const getProductReview = params => {
  return doFetch('/api/v1/products/' + params + '/reviews/', {
    method: 'GET',
  });
};

export const removeReview = id => {
  return doFetch('/product-review/' + id + '/delete', {
    method: 'DELETE',
  });
};

export const getSingleUserPointList = id => {

  return doFetch('/shop-users/' + id + '/reward-list/', {
    method: 'GET',
  });
};

export const getProductReviewNew = params => {
  return doFetch('/api/v1/products/' + params + '/reviews/', {
    method: 'GET',
  });
};

export const addOrUpdateProductReview = (params, id) => {
  return doFetch('api/v1/products/' + id + '/reviews/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
export const getProductDetail = params => {
  return doFetch('/admin-api/v1/products/' + params, {
    method: 'GET',
  });
};
export const getShopUsersPurchases = params => {
  return doFetch('/admin-api/v1/shop-users/' + params + '/product-purchases/', {
    method: 'GET',
  });
};
export const getShopUsersMoneySpents = params => {
  return doFetch('/admin-api/v1/shop-users/' + params + '/money-spents/', {
    method: 'GET',
  });
};
export const getShopUsersEarned = params => {
  return doFetch('/admin-api/v1/shop-users/' + params + '/earned-rewards/', {
    method: 'GET',
  });
};
export const getShopUsersGifts = params => {
  return doFetch('/admin-api/v1/shop-users/' + params + '/gifts/', {
    method: 'GET',
  });
};
export const getShopUsersMoneySpentsBonus = params => {

  return doFetch(
    '/admin-api/v1/shop-users/' + params + '/money-spent-bonuses/',
    {
      method: 'GET',
    },
  );
};
export const getProducts = () => {
  return doFetch('/admin-api/v1/products/');
};
export const sendNotification = params => {
  return doFetch('/admin-api/v1/send-notifications/', {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
export const bulkApprove = (params) => {
  return doFetch('/admin-api/v1/rewards/bulk-reward-status-change/', {
    method: 'POST',
    body: JSON.stringify({
      reward_ids: params.reward_ids,
      status: params.status
    }),
  })
    .then(() => {
      toast.success(`Rewards ${params.status === 'accepted' ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const bulkApprovePurchase = (params) => {
  return doFetch('/admin-api/v1/product-purchases/bulk-product-purchase-status-change/', {
    method: 'POST',
    body: JSON.stringify({
      purchase_ids: params.purchase_ids,
      accepted: params.accepted
    }),
  })
    .then(() => {
      toast.success(`Rewards ${params.accepted ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};
export const bulkApproveGift = (params) => {
  return doFetch('/admin-api/v1/gifts/bulk-gift-status-change/', {
    method: 'POST',
    body: JSON.stringify({
      gift_ids: params.gift_ids,
      status: params.status
    }),
  })
    .then(() => {
      toast.success(`Gifts ${params.status === 'accepted' ? 'accepted' : 'rejected'} successfully`)
    }
    )
    .catch(e => {
      toast.dismiss()
      console.log('error award save', e);
    });
};