import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../assets/images/nls-logo.png';
import {Button, Input} from '../../components';
import {useMutation} from 'react-query';
import toast from 'react-hot-toast';

import {forgotPasswordApi} from '@apis/';

const CheckMail = () => {
  const [email, setEmail] = useState('');

  const history = useHistory();

  const forgotPassword = useMutation(params => forgotPasswordApi(params));

  

  return (
    <div className='loginContainer'>
      <div className="loginWindow flex justify-center items-center">
        <div style={{width: 494}} className="flex borderRadiusLeft5 flex-auto flex-col items-center justify-center next-gradient h-screen p-16 relative">
          <div className="w-full items-center my-4">
            <h1 className="font-bold text-3xl text-white text-center">
              Check your mail!
            </h1>
            <p className="font-light text-xs text-white text-center py-3 px-20">
              A password reset link has been sent to your email, kindly check your mailbox to proceed.
            </p>
          </div>
        </div>
        <div style={{width: 400}} className="flex borderRadiusRight5 flex-grow-1 justify-center items-center next-gradient h-screen p-4">
          <img
            className="object-contain h-80 loginLogo"
            src={logo}
            alt="Next Level Technology Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default CheckMail;
