import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import moment from 'moment';
import { Eye, EyeOff } from "react-feather";

import '../../index.css';

import { Input, Checkbox, Button } from '@components';
import { registerApi } from '@apis';
import useAuth from '@context/useAuth';

import logo from '@images/nls-logo.png';

const Register = () => {
  const { onRegister } = useAuth();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeTos: false,
    showTerms: false,
  });
  const [signupPasswordVisibility, setSignupPasswordVisibility] =
    useState(false);
  const [signupCPasswordVisibility, setSignupCPasswordVisibility] =
    useState(false);

  const history = useHistory();

  const register = useMutation(params => registerApi(params));

  useEffect(() => {
    if (register?.isError) {
      if (typeof register?.error === 'string') {
        toast.error(register?.error);
      } else {
        toast.error('register Error');
      }
      register?.reset();
    }

    if (register?.isSuccess) {
      onRegister({ token: register?.data?.api_token });
      history.replace('/');
    }
  }, [history, onRegister, register]);

  const handleRegister = async e => {
    e.preventDefault();
    const { email, password, firstName, lastName, confirmPassword } = state;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      await toast.error('Please enter valid email');
      return false
    }
    if (password !== confirmPassword) {
      await toast.error('Password does not match');
      return false
    } else {
      await register.mutate({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleRegister(e);
    }
  };

  const showContent = () => {
    if (!state.showTerms) {
      return (
        <>
          <Input
            dark
            white
            value={state.firstName}
            onChange={e => setState({ ...state, firstName: e.target.value })}
            label="First Name"
            placeholder="Enter First Name"
          />
          <Input
            dark
            white
            value={state.lastName}
            onChange={e => setState({ ...state, lastName: e.target.value })}
            label="Last Name"
            placeholder="Enter Last Name"
          />
          <Input
            type='email'
            dark
            white
            value={state.email}
            onChange={e => setState({ ...state, email: e.target.value })}
            label="Email"
            placeholder="Enter Email"
          />

          <div className='position-rel'>
            <Input
              dark
              white
              type={signupPasswordVisibility ? "text" : "password"}
              value={state.password}
              onChange={e => setState({ ...state, password: e.target.value })}
              label="Password"
              placeholder="Enter Password"
            />
            <div
              className="password-toggle"
              onClick={() => {
                setSignupPasswordVisibility(
                  !signupPasswordVisibility
                );
              }}
            >
              {signupPasswordVisibility ? (
                <Eye size="14" color="black" />
              ) : (
                <EyeOff size="14" color="black" />
              )}
            </div>
          </div>

          <div className='position-rel'>
            <Input
              dark
              white
              type={signupCPasswordVisibility ? "text" : "password"}
              value={state.confirmPassword}
              onChange={e =>
                setState({ ...state, confirmPassword: e.target.value })
              }
              label="Confirm Password"
              placeholder="Confirm Password"
              onKeyDown={handleKeyDown}
            />
            <div
              className="password-toggle"
              onClick={() => {
                setSignupCPasswordVisibility(
                  !signupCPasswordVisibility
                );
              }}
            >
              {signupCPasswordVisibility ? (
                <Eye size="14" color="black" />
              ) : (
                <EyeOff size="14" color="black" />
              )}
            </div>
          </div>

        </>
      );
    } else {
      return (
        <div className="loginTerms text-white">
          <h1 className="font-bold">Terms and Conditions</h1>
          <p className="font-bold text-xs text-white">
            NEXT LEVEL TECHNOLOGY, US CORPORATION SOFTWARE AND SERVICES TERMS OF
            USE
          </p>
          <div
            style={{
              maxHeight: 375,
              maxWidth: 425,
              overflowY: 'scroll',
              marginTop: 15,
              marginBottom: 15,
              scrollbarWidth: 1,
              paddingRight: 15,
            }}>
            <p className="font-light text-xs text-white">
              BY ACCEPTING THESE TERMS, BY (1) CLICKING A BOX OR BUTTON
              INDICATING ACCEPTANCE, (2) EXECUTING AN ORDER FORM THAT REFERENCES
              THESE TERMS, OR (3) USING A FREE PRODUCT, YOU AGREE TO THESE
              TERMS. IF YOU REGISTER FOR NEXT LEVEL Technology, US’ FREE
              PRODUCT, THE APPLICABLE PROVISIONS OF THESE TERMS WILL ALSO GOVERN
              THAT FREE PRODUCT. IF THE INDIVIDUAL ACCEPTING THESE TERMS IS
              ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH
              INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH
              ENTITY AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE THE TERM
              “YOU” REFERS TO SUCH ENTITY AND ITS AFFILIATES. IF THE INDIVIDUAL
              ACCEPTING THESE TERMS DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT
              AGREE WITH THESE TERMS, SUCH INDIVIDUAL MUST NOT ACCEPT THESE
              TERMS AND MAY NOT USE THE PLATFORM.
            </p>
            <br />
            <p className="font-bold text-xs text-white">1. Platform.</p>
            <p className="font-light text-xs text-white">
              Subject to the terms and conditions of these Terms, Next Level
              Technology, US grants you during the Term (defined below) a
              limited, non-exclusive, non-transferable, non-sublicenseable right
              to access and use the Next Level Technology, US website, software
              development platform, engineering workforce and module library
              (collectively, the{' '}
              <b className="font-bold text-xs text-white">“Platform”</b>) for
              the purpose of managing, hosting, developing, launching, and
              analyzing your proprietary software applications (
              <b className="font-bold text-xs text-white">“Apps”</b>).
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              2. Professional Services.
            </p>
            <p className="font-light text-xs text-white">
              Subject to the terms and conditions of these Terms, Next Level
              Technology, US will perform the professional engineering, design,
              or product management Services set forth on an Order Form or as
              otherwise requested through the Platform, which may include
              managed use of the Platform by Next Level Technology, US on your
              behalf (<b className="font-bold text-xs text-white">“Services”</b>
              ). The manner and means by which Next Level Technology, US chooses
              to complete the Services are in Next Level Technology', US’ sole
              discretion and control. Next Level Technology, US’ obligations to
              provide the Services are conditioned upon receiving such
              information and cooperation from you as may be reasonably
              necessary to perform the Services.
            </p>
            <br />
            <p className="font-bold text-xs text-white">3. Restrictions.</p>
            <p className="font-light text-xs text-white">
              The rights granted to you in these Terms are subject to the
              following restrictions: (a) you may not license, sell, rent,
              lease, transfer, assign, distribute, host, or otherwise
              commercially exploit the Platform, whether in whole or in part, or
              any content displayed on the Platform; (b) you may not modify,
              make derivative works of, disassemble, reverse compile or reverse
              engineer any part of the Platform; and (c) except as expressly
              stated herein, no part of the Platform may be copied, reproduced,
              distributed, republished, downloaded, displayed, posted or
              transmitted in any form or by any means. Unless otherwise
              indicated, any future release, update, or other addition to
              functionality of the Platform is subject to these Terms. Unless
              otherwise specified on the Platform, all copyright and other
              proprietary notices on the Platform (or on any content displayed
              on the Platform) must be retained on all copies thereof.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              4. Your Responsibilities.
            </p>
            <p className="font-light text-xs text-white">
              You will (a) be responsible for your authorized users’ (
              <b className="font-bold text-xs text-white">“Users”</b>)
              compliance with these Terms and Order Forms, (b) be responsible
              for the accuracy, quality and legality of User Content (defined
              below), (c) be responsible for your use of your Apps (including
              any Invention and any Prior Invention or Reusable Module (each
              defined below) incorporated into an Invention) and the
              interoperation of your App with any third party application, (d)
              use commercially reasonable efforts to prevent unauthorized access
              to or use of Platform, and notify Next Level Technology, US
              promptly of any such unauthorized access or use, and (e) use the
              Platform and Services only in accordance with these Terms and
              applicable laws and government regulations. Any use of the
              Platform in breach of the foregoing by you or Users that in Next
              Level Technology, US’ judgment threatens the security, integrity
              or availability of Next Level Technology’ Services, may result in
              Next Level Technology, US’ immediate suspension of the Platform,
              however Next Level Technology, US will use commercially reasonable
              efforts under the circumstances to provide you with notice and an
              opportunity to remedy such violation or threat prior to any such
              suspension.
            </p>
            <br />
            <p className="font-bold text-xs text-white">5. Accounts.</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a. Registration.</b>{' '}
              In order to use certain features of the Platform, you must
              register for an account (“Account”) and provide certain
              information about yourself as prompted by the account registration
              form. You represent and warrant that: (i) all required
              registration information you submit is truthful and accurate; (ii)
              you will maintain the accuracy of such information. You may delete
              your Account at any time, for any reason, by following the
              instructions on the Platform. Next Level Technology, US may
              suspend or terminate your Account in accordance with Section 9.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                b. Responsibilities.
              </b>{' '}
              You are responsible for maintaining the confidentiality of your
              Account login information and are fully responsible for all
              activities that occur under your Account. You agree to immediately
              notify Next Level Technology, US of any unauthorized use, or
              suspected unauthorized use of your Account or any other breach of
              security. Next Level Technology, US cannot and will not be liable
              for any loss or damage arising from your failure to comply with
              the above requirements.
            </p>
            <br />
            <p className="font-bold text-xs text-white">7. Ownership.</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a. Platform.</b> Next
              Level Technology, US retains all rights in the Platform, except as
              expressly set forth in these Terms.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">b. Apps.</b> You own
              your Apps, subject to the terms and conditions of these Terms.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">c. Inventions.</b> All
              rights in and to any copyrightable software, algorithm, code,
              material, notes, records, drawings, designs, inventions,
              improvements, developments, discoveries and trade secrets (
              <b className="font-bold text-xs text-white">“Materials”</b>)
              conceived, discovered, authored, invented, developed or reduced to
              practice by Next Level Technology, US, solely or in collaboration
              with others, during the Term and arising out of, or in connection
              with, performing the Services under these Terms and any
              copyrights, patents, trade secrets, mask work rights or other
              intellectual property rights relating to the foregoing
              (collectively, but excluding Prior Inventions and Reusable Modules{' '}
              <b className="font-bold text-xs text-white">“Inventions”</b>), are
              your sole property. Next Level Technology, US will promptly
              disclose any Invention to you in writing and will deliver and
              assign (or cause to be assigned) and hereby assigns fully to you
              all right, title and interest in and to the Inventions. Next Level
              Technology, US will reasonably assist you to further evidence,
              record and perfect such assignments, and to perfect, obtain,
              maintain, enforce and defend any rights assigned. Notwithstanding
              the foregoing, Next Level Technology, US reserves the right to use
              and re-use any generalized knowledge it gains arising from the
              performance of Services in its business without liability to you.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                d. Prior Inventions.
              </b>{' '}
              If, in the course of performing Services, Next Level Technology
              incorporates into any Invention or uses any pre-existing Materials
              owned by Next Level Technology, US or in which Next Level
              Technology, US has an interest (
              <b className="font-bold text-xs text-white">“Prior Inventions”</b>
              ), then Next Level Technology, US grants you a non-exclusive,
              royalty-free, perpetual, irrevocable, transferable, worldwide,
              sublicenseable license to make, have made, use, import, offer for
              sale, sell, reproduce, distribute, modify, adapt, prepare
              derivative works of, display, perform, and otherwise exploit such
              Prior Inventions solely as incorporated into Inventions.
            </p>
            <br />
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                e. Reusable Modules.
              </b>{' '}
              Any open-source components Next Level Technology uses in
              performing the Services, whether pre-existing or created by Next
              Level Technology (“Reusable Modules”) are licensed to you pursuant
              to the terms of the applicable open source license. For clarity,
              and notwithstanding anything to the contrary in these Terms, Next
              Level Technology may develop new Reusable Modules based on your
              requests in connection with the Services. Next Level Technology
              will not incorporate any of your User Content into a Reusable
              Module without your prior consent.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              8. Relationship of Parties.
            </p>
            <p className="font-light text-xs text-white">
              Next Level Technology, US and you are independent contractors. You
              understand that Next Level Technology, US is not an employee, and
              that the performance of Services requires a collaborative,
              professional relationship of equals where mutual professional
              respect, courtesy and consideration are expected. Due to the
              virtual nature of the relationship, you understand the importance
              of communication, and agree to respond to questions, requests and
              communications from Next Level Technology, US in a timely manner.
              You understand that Next Level Technology, US is a business with
              other clients, and requires fair, realistic notice in order to
              attend to requests and projects. You understand that Next Level
              Technology, US may require detailed clarification of tasks in
              order to meet expectations and provide the best support and
              highest quality work, and agrees to provide specifications at the
              level of detail required by Next Level Technology, US.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              9. Term; Termination.
            </p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a.</b> The term of
              these Terms begins on the date you accept these Terms and
              continues until all subscriptions have expired or been terminated,
              unless otherwise set forth on an Order Form (the{' '}
              <b className="font-bold text-xs text-white">“Term”</b>). Unless
              otherwise specified on an Order Form, subscriptions will
              automatically renew for additional periods equal to the expiring
              subscription term, unless either party gives the other notice of
              non-renewal at least 30 days before the end of the relevant
              subscription term, by cancelling the user’s subscription via the
              Next Level Technology, US web product.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                b. Effect of Termination.
              </b>{' '}
              Upon termination of these Terms, your right to use the Platform
              will immediately cease and any fees you owe to Next Level
              Technology, US will become immediately due. Sections 3, 4, 7, 8b,
              10 – 14, 15.b, and 16 – 23 will survive any termination or
              expiration of these Terms. Next Level Technology, US may delete
              any User Content hosted on the Platform.
            </p>
            <br />
            <p className="font-bold text-xs text-white">10. Fees.</p>
            <p className="font-light text-xs text-white">
              The fees for the Platform and any Services are as set forth on the
              Order Form, or, if you do not have an Order Form, the Platform.
              You will provide Next Level Technology, US with valid and updated
              credit card information, and you authorize Next Level Technology,
              US to charge such credit card for the fees for the initial
              subscription term, hourly or milestone-based service fees, any
              renewal subscription term, in advance, in accordance with the
              billing frequency set forth on the Order Form, or if there is no
              Order Form, the Platform. Subscriptions, hourly fees, and
              milestone-based fees are non-refundable once hours are worked and
              charged (for hourly fees) or when milestones are funded for work
              (for milestone fees).
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              11. No Support or Maintenance.
            </p>
            <p className="font-light text-xs text-white">
              Unless you have a subscription that provides for support, you
              acknowledge and agree that Next Level Technology, US will have no
              obligation to provide you with any support or maintenance in
              connection with the Platform except as part of the Services.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                12. Acceptable Use Policy.
              </b>{' '}
              The following terms constitute Next Level Technology, US’{' '}
              <b className="font-bold text-xs text-white">
                “Acceptable Use Policy”
              </b>
              :
            </p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a.</b> You will not
              use the Platform to collect, upload, transmit, display, or
              distribute any User Content (i) that violates any third-party
              right, including any copyright, trademark, patent, trade secret,
              moral right, privacy right, right of publicity, or any other
              intellectual property or proprietary right; (ii) that is unlawful,
              harassing, abusive, tortious, threatening, harmful, invasive of
              another’s privacy, vulgar, defamatory, false, intentionally
              misleading, trade libelous, pornographic, obscene, patently
              offensive, promotes racism, bigotry, hatred, or physical harm of
              any kind against any group or individual or is otherwise
              objectionable; (iii) that is harmful to minors in any way; or (iv)
              that is in violation of any law, regulation, or obligations or
              restrictions imposed by any third party.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">b.</b> In addition,
              you will not: (i) upload, transmit, or distribute to or through
              the Platform any computer viruses, worms, or any software intended
              to damage or alter a computer system or data; (ii) send through
              the Platform unsolicited or unauthorized advertising, promotional
              materials, junk mail, spam, chain letters, pyramid schemes, or any
              other form of duplicative or unsolicited messages, whether
              commercial or otherwise; (iii) use the Platform to harvest,
              collect, gather or assemble information or data regarding other
              users, including e-mail addresses, without their consent; (iv)
              interfere with, disrupt, or create an undue burden on servers or
              networks connected to the Platform, or violate the regulations,
              policies or procedures of such networks; (v) attempt to gain
              unauthorized access to the Platform (or to other computer systems
              or networks connected to or used together with the Platform),
              whether through password mining or any other means; (vi) harass or
              interfere with any other user’s use and enjoyment of the Platform;
              or (vi) use software or automated agents or scripts to produce
              multiple accounts on the Platform, or to generate automated
              searches, requests, or queries to (or to strip, scrape, or mine
              data from) the Platform (provided, however, that we conditionally
              grant to the operators of public search engines revocable
              permission to use spiders to copy materials from the Platform for
              the sole purpose of and solely to the extent necessary for
              creating publicly available searchable indices of the materials,
              but not caches or archives of such materials, subject to the
              parameters set forth in our robots.txt file).
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">c.</b> Enforcement. We
              reserve the right (but have no obligation) to review any User
              Content, and to investigate and/or take appropriate action against
              you in our sole discretion if you violate this Acceptable Use
              Policy or any other provision of these Terms or otherwise create
              liability for us or any other person. Such action may include
              removing or modifying your User Content, terminating your Account
              in accordance with Section 9, and/or reporting you to law
              enforcement authorities.
            </p>
            <br />
            <p className="font-bold text-xs text-white">‍13. Feedback; Name.</p>
            <p className="font-light text-xs text-white">
              If you provide Next Level Technology, US with any feedback or
              suggestions regarding the Platform or Services (
              <b className="font-bold text-xs text-white">“Feedback”</b>), then
              you grant Next Level Technology, US a perpetual, irrevocable,
              non-exclusive, transferable, sublicensable, worldwide license to
              use such Feedback in any manner it deems appropriate. Next Level
              Technology, US will treat any Feedback as non-confidential and
              non-proprietary. Unless you provide Next Level Technology, US with
              an opt-out notice, you grant Next Level Technology, US a right to
              use your name, logo, and application screenshots in Next Level
              Technology, US’ marketing materials. Any such opt-out notice will
              not apply to use by Next Level Technology, US in accordance with
              this Section prior to Next Level Technology, US’ receipt of
              notice.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              ‍14. Representations and Warranties.
            </p>
            <p className="font-light text-xs text-white">
              You represent and warrant that you have the full right and
              authority to enter into and perform these Terms, and that entering
              into these Terms does not violate any outstanding assignments,
              grants, licenses, encumbrances, obligations, agreements or
              understanding to which you are a party. Next Level Technology, US
              warrants that during an applicable subscription term Next Level
              Technology, US will not materially decrease the overall
              functionality of the Platform. For any breach of that warranty,
              your exclusive remedies are those described in Section 9.
            </p>
            <br />
            <p className="font-bold text-xs text-white">15. Free Services.</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a.</b> Next Level
              Technology, US may make certain parts of the Platform available
              for free (
              <b className="font-bold text-xs text-white">“Free Products”</b>).
              Use of Free Products is subject to the terms and conditions of
              these Terms. In the event of a conflict between this section and
              any other portion of these Terms, this section controls. Free
              Products are provided to you without charge as described on the
              Platform. You agree that Next Level Technology, US, in its sole
              discretion and for any or no reason, may terminate your access to
              the Free Products or any part thereof, with or without prior
              notice. Next Level Technology, US will not be liable to you or any
              third party for such termination. You are solely responsible for
              exporting your User Content and any Apps from the Free Products
              prior to termination of your access to the Free Products for any
              reason, provided that if Next Level Technology, US terminates your
              account, except as required by law, Next Level Technology, US will
              provide you a reasonable opportunity to retrieve your Apps.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">b.</b> NOTWITHSTANDING
              SECTIONS 14, 16, AND 18.b, THE FREE PRODUCTS ARE PROVIDED “AS-IS”
              AND WITHOUT ANY WARRANTY, AND NEXT LEVEL Technology, US HAS NO
              INDEMNIFICATION OBLIGATIONS NOR LIABILITY OF ANY TYPE WITH RESPECT
              TO THE FREE PRODUCTS UNLESS SUCH EXCLUSION OF LIABILITY IS NOT
              ENFORCEABLE UNDER APPLICABLE LAW IN WHICH CASE NEXT LEVEL
              Technology, US’ LIABILITY WITH RESPECT TO THE FREE PRODUCTS WILL
              NOT EXCEED US$100. WITHOUT LIMITING THE FOREGOING, NEXT LEVEL
              Technology, US AND ITS SUPPLIERS DO NOT REPRESENT OR WARRANT TO
              YOU THAT: (I) YOUR USE OF THE FREE PRODUCTS WILL MEET YOUR
              REQUIREMENTS, (II) YOUR USE OF THE FREE PRODUCTS WILL BE
              UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (III) ANY
              DATA PROVIDED THROUGH THE FREE PRODUCTS WILL BE ACCURATE.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY IN SECTION 17, YOU ARE
              FULLY LIABLE UNDER THESE TERMS TO NEXT LEVEL Technology, US FOR
              ANY DAMAGES ARISING OUT OF YOUR USE OF THE FREE PRODUCTS, ANY
              BREACH BY YOU OF THESE TERMS AND ANY OF YOUR INDEMNIFICATION
              OBLIGATIONS HEREUNDER.
            </p>
            <br />
            <p className="font-bold text-xs text-white">16. Disclaimers.</p>
            <p className="font-light text-xs text-white">
              EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, NEITHER PARTY MAKES
              ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
              OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED
              WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE
              FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              17. Limitation of Liability.
            </p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a.</b> TO THE FULLEST
              EXTENT PERMITTED BY LAW, IN NO EVENT WILL NEXT LEVEL Technology,
              US (OR ITS SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
              LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE
              PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
              SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE
              TERMS OR YOUR USE OF, OR INABILITY TO USE, THE PLATFORM OR
              SERVICES, EVEN IF NEXT LEVEL Technology, US HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
              PLATFORM AND SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU
              WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
              COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">b.</b> TO THE FULLEST
              EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY,
              NEXT LEVEL TECHNOLOGY, US’ LIABILITY TO YOU FOR ANY DAMAGES
              ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER
              AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
              LIMITED TO THE GREATER OF (I) THE FEES YOU HAVE PAID NEXT LEVEL
              TECHNOLOGY, US IN THE 12 MONTHS PRIOR TO THE EVENT GIVING RISE TO
              LIABILITY AND (II) US$50. THE EXISTENCE OF MORE THAN ONE CLAIM
              WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL
              HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE
              TERMS.
            </p>
            <br />
            <p className="font-bold text-xs text-white">18. Indemnification</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">a. By You.</b> To The
              fullest extent permitted by law, you will indemnify Next Level
              Technology, US from and against any and all liabilities, suits,
              losses, damages and judgments, costs and expenses (including
              reasonable outside counsel's fees and expenses) in connection with
              (i) your use of the Platform or Services in a manner not
              authorized under these Terms, (ii) any other breach of these Terms
              by you, and (iii) your use of Prior Inventions or Reusable Modules
              in a manner not authorized under these Terms.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">b.</b> By Next Level
              Technology, US. Unless you are using a Free Product, Next Level
              Technology, US will indemnify and defend you against any claim,
              demand, suit or proceeding made or brought against you by a third
              party alleging that the Platform or any Prior Invention infringes
              or misappropriates such third party’s intellectual property rights
              (an “IP Claim”), provided that you (i) promptly give Next Level
              Technology, US written notice of the IP Claim, (ii) give Next
              Level Technology, US sole control of the defense and settlement of
              the IP Claim, and (iii) give Next Level Technology, US all
              reasonable assistance, at Next Level Technology, US’ expense. The
              foregoing obligations do not apply if (1) the allegation does not
              state with specificity that the Platform or a Prior Invention are
              the basis of the IP Claim; (2) an IP Claim arises from the use or
              combination of the Platform or Prior Invention (other than as
              incorporated into an Invention) with software, hardware, data, or
              processes not provided by Next Level Technology, US, if the
              Platform, Prior Invention or use thereof would not infringe
              without such combination; (3) an IP Claim arises from Services for
              which there is no charge; or (4) an IP Claim arises from User
              Content or your breach of these Terms. If Next Level Technology,
              US believes an infringement or misappropriation claim related to
              the Platform or a Prior Invention is likely, then Next Level
              Technology, US may: (A) modify the Platform or Prior Invention to
              be non-infringing, (B) obtain a license for your continued use of
              the Platform or Prior Invention, or (C) terminate your
              subscription for the Platform upon 30 days’ written notice and
              refund you any prepaid fees covering the remainder of the term of
              the terminated subscriptions.‍
            </p>
            <br />
            <p className="font-bold text-xs text-white">19. Confidentiality.</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                a. Definition. “Confidential Information”
              </b>{' '}
              means all information disclosed by a party (
              <b className="font-bold text-xs text-white">“Disclosing Party”</b>
              ) to the other party (
              <b className="font-bold text-xs text-white">“Receiving Party”</b>
              ), whether orally or in writing, that is designated as
              confidential or that reasonably should be understood to be
              confidential given the nature of the information and the
              circumstances of disclosure. Next Level Technology, US’
              Confidential Information includes the Platform and any Prior
              Inventions, and the terms and conditions of all Order Forms
              (including pricing). Confidential Information of each party
              includes business and marketing plans, technology and technical
              information, product plans and designs, and business processes
              disclosed by such party. However, Confidential Information does
              not include any information that (i) is or becomes generally known
              to the public without breach of any obligation owed to the
              Disclosing Party, (ii) was known to the Receiving Party prior to
              its disclosure by the Disclosing Party without breach of any
              obligation owed to the Disclosing Party, (iii) is received from a
              third party without breach of any obligation owed to the
              Disclosing Party, or (iv) was independently developed by the
              Receiving Party.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                b. Non-Use and Non-Disclosure.
              </b>
              As between the parties, each party retains all ownership rights in
              and to its Confidential Information. The Receiving Party will use
              the same degree of care that it uses to protect the
              confidentiality of its own confidential information of like kind
              (but not less than reasonable care) to (i) not use any
              Confidential Information of the Disclosing Party for any purpose
              outside the scope of these Terms and (ii) except as otherwise
              authorized by the Disclosing Party in writing, limit access to
              Confidential Information of the Disclosing Party to those of its
              employees and contractors who need that access for purposes
              consistent with these Terms and who have signed confidentiality
              agreements with the Receiving Party containing protections not
              materially less protective of the Confidential Information than
              those in these Terms. Neither party will disclose the terms of
              these Terms or any Order Form to any third party other than its
              affiliates, legal counsel and accountants without the other
              party’s prior written consent.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                c. Compelled Disclosure.
              </b>
              The Receiving Party may disclose Confidential Information of the
              Disclosing Party to the extent compelled by law to do so, provided
              the Receiving Party gives the Disclosing Party prior notice of the
              compelled disclosure (to the extent legally permitted) and
              reasonable assistance, at the Disclosing Party's cost, if the
              Disclosing Party wishes to contest the disclosure. If the
              Receiving Party is compelled by law to disclose the Disclosing
              Party’s Confidential Information as part of a civil proceeding to
              which the Disclosing Party is a party, and the Disclosing Party is
              not contesting the disclosure, the Disclosing Party will reimburse
              the Receiving Party for its reasonable cost of compiling and
              providing secure access to that Confidential Information.
            </p>
            <br />
            <p className="font-bold text-xs text-white">
              ‍20. Electronic Communications.{' '}
            </p>
            <p className="font-light text-xs text-white">
              By using the Platform, you consent to receiving electronic
              communications from Next Level Technology, US (either by posting
              notices on the Platform or via email). You agree that any notices
              Next Level Technology, US is required to provide you may be
              provided electronically.
            </p>
            <br />
            <p className="font-bold text-xs text-white">‍21. Export.</p>
            <p className="font-light text-xs text-white">
              The Platform and any Inventions, Prior Inventions and Reusable
              Modules may be subject to U.S. export control laws and may be
              subject to export or import regulations in other countries. You
              agree not to export, reexport, or transfer, directly or
              indirectly, any U.S. technical data acquired from Next Level
              Technology, US, or any products utilizing such data, in violation
              of the United States export laws or regulations.
            </p>
            <br />
            <p className="font-bold text-xs text-white">22. Arbitration.</p>
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                a. We Both Agree To Arbitrate.
              </b>{' '}
              You and Next Level Technology, US agree to resolve any claims
              relating to these Terms, the Platform or any Services through
              final and binding arbitration by a single arbitrator, except as
              set forth under Section 22.e. This includes disputes arising out
              of or relating to interpretation or application of this
              “Arbitration” section, including its enforceability, revocability,
              or validity.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                b. Informal Resolution.{' '}
              </b>{' '}
              Before filing a claim, each party agrees to try to resolve the
              dispute by contacting the other party. If a dispute is not
              resolved within thirty days of notice, either party may bring a
              formal proceeding.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">c. Arbitration.</b>{' '}
              The{' '}
              <b className="font-bold text-xs text-white underlined">
                American Arbitration Association
              </b>{' '}
              (AAA) will administer the arbitration under its Commercial
              Arbitration Rules. The arbitration will be held in San Francisco
              (CA), or any other location both parties agree to in writing.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                ‍d. Exception to Arbitration.
              </b>{' '}
              Either Party may bring a lawsuit in the federal or state courts of
              Alameda County, North Carolina solely for injunctive relief to
              stop unauthorized use or abuse of the Platform or infringement of
              intellectual property rights without first engaging in the
              informal dispute notice process described above. Both you and Next
              Level Technology, US consent to venue and personal jurisdiction
              there.
            </p>
            <br />
            <p className="font-light text-xs text-white">
              <b className="font-bold text-xs text-white">
                ‍e. NO CLASS ACTIONS.
              </b>{' '}
              You may only resolve disputes with Next Level Technology, US on an
              individual basis and will not bring a claim in a class,
              consolidated or representative action. Class arbitrations, class
              actions, private attorney general actions and consolidation with
              other arbitrations are not allowed.
            </p>
            <br />
            <p className="font-bold text-xs text-white">‍23. Miscellaneous.</p>
            <p className="font-light text-xs text-white">
              No failure or delay by either party in exercising any right under
              these Terms will constitute a waiver of that right. If any term or
              provision of these Terms is determined to be illegal or invalid,
              such illegality or invalidity will not affect the validity of the
              remainder of these Terms. These Terms are governed by North
              Carolina law. These Terms, which include any Order Form, are the
              entire agreement between the parties hereto with respect to the
              subject matter hereof. Neither party may assign these Terms
              without the other party’s consent, except that Next Level
              Technology, US may assign these Terms without consent in
              connection with any merger where Next Level Technology, US is not
              the surviving entity, reorganization, or sale of all or
              substantially all of its assets. These Terms will be binding upon
              each party’s successors and permitted assigns.
            </p>
            <br />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginWindow flex justify-center items-center">
        <div
          style={{ width: 494 }}
          className="borderRadiusLeft5 flex flex-col flex-auto items-center justify-center next-gradient h-screen p-16 relative">
          <div className="w-full items-center my-4 px-20">
            <h1 className="font-bold text-3xl text-white text-center">
              Welcome
            </h1>
            <p className="font-light text-xs text-white text-center py-3">
              Create a new account
            </p>
          </div>
          <div className="w-full items-center">
            {showContent()}
            <Checkbox
              checked={state.agreeTos}
              onChange={e => setState({ ...state, agreeTos: e.target.checked })}
              label={
                <p className="text-white text-xs">
                  By signing up you agree to the Next Level Technology
                  <Link
                    className="text-white text-xs px-2 underline"
                    onClick={() =>
                      setState({ ...state, showTerms: !state.showTerms })
                    }>
                    Terms and conditions
                  </Link>
                </p>
              }
            />
          </div>
          <div
            className="w-full inset-x-0 bottom-14"
            style={{ margin: '0 60px' }}>
            <Button
              block
              onClick={handleRegister}
              loading={register?.isLoading}
              disabled={
                register?.isLoading ||
                !state.firstName ||
                !state.lastName ||
                !state.password ||
                !state.confirmPassword ||
                !state.agreeTos
              }
              label="Sign Up"
            />
            <div className="flex flex-row justify-center items center my-5">
              <p className="text-white text-xs">Already have an account?</p>
              <Link to="/" className="text-white text-xs px-2 underline">
                Sign in now
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{ width: 400 }}
          className="flex borderRadiusRight5 justify-center items-center next-gradient h-screen p-4">
          <img
            className="object-contain h-80 loginLogo"
            src={logo}
            alt="Next Level Technology Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
