import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useHistory } from 'react-router-dom';
import logo from '../../assets/images/nls-logo.png';
import { Button, Input } from '../../components';
import { useMutation } from 'react-query';
import { Eye, EyeOff } from "react-feather";

import { resetPasswordApi } from '@apis/';

const ResetPassword = () => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signupPasswordVisibility, setSignupPasswordVisibility] =
    useState(false);
  const [signupCPasswordVisibility, setSignupCPasswordVisibility] =
    useState(false);

  const location = useLocation();
  const history = useHistory();

  const resetPassword = useMutation(params => resetPasswordApi(params));

  useEffect(() => {
    const params = location?.search;
    setEmail(new URLSearchParams(params).get('email'));
    setOtp(new URLSearchParams(params).get('token'));
  }, [location?.search]);

  useEffect(() => {
    if (resetPassword?.isError) {
      if (typeof resetPassword?.error === 'string') {
        toast.error(resetPassword?.error);
      } else {
        toast.error('Failed to reset password');
      }
      resetPassword?.reset();
    }

    if (resetPassword?.isSuccess) {
      history.push('/login');
    }
  }, [email, resetPassword, history]);

  const handleReset = async e => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password doesn't match");
    } else {
      await resetPassword.mutateAsync({
        email,
        token: otp,
        new_password: password,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleReset(e);
    }
  }

  return (
    <div className='loginContainer'>
      <div className="loginWindow flex justify-center items-center">
        <div style={{ width: 494 }} className="flex borderRadiusLeft5 flex-col flex-auto items-center justify-center next-gradient h-screen p-16 relative">
          <div className="w-full items-center my-4 px-20">
            <h1 className="font-bold text-3xl text-white text-center">
              Forgot my password
            </h1>
            <p className="font-light text-xs text-white text-center py-3">
              Setup a new password
            </p>
          </div>
          <div className="w-full items-center">
            <div className='position-rel'>
              <Input
                dark
                white
                type={signupPasswordVisibility ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                label="New Password"
                placeholder="Enter New Password"
              />
              <div
                className="password-toggle"
                onClick={() => {
                  setSignupPasswordVisibility(
                    !signupPasswordVisibility
                  );
                }}
              >
                {signupPasswordVisibility ? (
                  <Eye size="14" color="black" />
                ) : (
                  <EyeOff size="14" color="black" />
                )}
              </div>
            </div>
            <div className='position-rel'>
              <Input
                dark
                white
                type={signupCPasswordVisibility ? "text" : "password"}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                label="Confirm Password"
                placeholder="Confirm Password"
                onKeyDown={handleKeyDown}
              />
              <div
                className="password-toggle"
                onClick={() => {
                  setSignupCPasswordVisibility(
                    !signupCPasswordVisibility
                  );
                }}
              >
                {signupCPasswordVisibility ? (
                  <Eye size="14" color="black" />
                ) : (
                  <EyeOff size="14" color="black" />
                )}
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 bottom-24"
            style={{ margin: '0 60px' }}>
            <Button
              block
              disabled={!password || !confirmPassword || resetPassword?.isLoading}
              loading={resetPassword?.isLoading}
              label="Reset My Password"
              onClick={handleReset}
            />
            <div className="flex flex-row justify-center items center my-5">
              <p className="text-white text-xs">Don't have an account?</p>
              <Link to="/register" className="text-white text-xs px-2 underline">
                Sign up now
              </Link>
            </div>
            <div className="flex flex-row justify-center items center my-5">
              <p className="text-white text-xs">Already have an account?</p>
              <Link to="/" className="text-white text-xs px-2 underline">
                Sign in now
              </Link>
            </div>
          </div>
        </div>
        <div style={{ width: 400 }} className="flex borderRadiusRight5 justify-center items-center next-gradient h-screen p-4">
          <img
            className="object-contain h-80 loginLogo"
            src={logo}
            alt="Next Level Technology Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
