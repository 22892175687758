import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useRowSelect, useSortBy } from 'react-table';
import moment from 'moment';
import { ContentWrapper, Button, Switch } from '@components';
import {
  getReward,
  getPurchases,
  Acceptapi,
  AcceptPurchaseapi,
  getRewardNewApi,
  getGiftData,
  changeRewardStatus,
  changeGiftStatus,
  ProductPurchaseNew,
  bulkApprove, bulkApprovePurchase, bulkApproveGift
} from '@apis';
import { ReactComponent as Gift } from '@images/gift.svg';
import { ReactComponent as User } from '@images/user.svg';
import { ReactComponent as Basket } from '@images/basket.svg';
import { ReactComponent as Star } from '@images/star.svg';
import debby from '@images/debby.png';
import { useMutation } from 'react-query';
import { data } from 'autoprefixer';
import toast from 'react-hot-toast';



const Approve = () => {
  const [acceptearn, setacceptearnData] = useState([]);
  const [rejectearn, setrejectearnData] = useState([]);
  const [acceptpurchase, setacceptpurchaseData] = useState([]);
  const [rejectpurchase, setrejectpurchaseData] = useState([]);
  const [RewardData, setRewardData] = useState([]);
  const [GiftData, setGiftData] = useState([]);
  const [PurchaseData, setPurchaseData] = useState([]);
  const [columns, setColumn] = useState('1');
  const [selectedRow, setSelectedRow] = useState([])
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rewardNextPage, setRewardNextPage] = useState('');
  const [rewardpreviousPage, setRewardPreviousPage] = useState('');
  const [giftNextPage, setGiftNextPage] = useState('');
  const [giftpreviousPage, setGiftPreviousPage] = useState('');
  const [purchaseNextPage, setPurchaseNextPage] = useState('');
  const [purchasepreviousPage, setPurchasePreviousPage] = useState('');
  const [loading, setLoading] = useState(false)
  const [rewardCount, setRewardCount] = useState(0)
  const [giftCount, setGiftCount] = useState(0)
  const [purchaseCount, setPurchaseCount] = useState(0)

  const getuseData = async val => {
    setColumn(val);
  };
  const RewardAccept = useMutation(params => Acceptapi(params));
  const PurchaseAccept = useMutation(params => AcceptPurchaseapi(params));

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    },
  );


  const Table = ({ columns, data, onSelectedRowChange, loading, selectedRow }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      state: { selectedRowIds },
    } = useTable({
      columns, data, onRowSelectionChange: (row) => {
      },
    }, useSortBy, useRowSelect, hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onClick={(e) => {
                onSelectedRowChange(e, 1, data, 'all')
              }} checked={data.length === selectedRow.length && data.length !== 0} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} checked={selectedRow.includes(row.original.id)} onClick={(e) => {
                onSelectedRowChange(e, row.original.id, data)
              }} className="cursor-pointer" />
            </div>
          ),
        },
        ...columns,
      ]);
    });

    return (
      <table {...getTableProps()} className="min-w-full">
        <thead className="bg-tableHeaderBg p-4">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {loading ? <div className='mt-5 pt-5'>Loading....</div> : <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-4 whitespace-nowrap text-sm">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>}
      </table>
    );
  };

  const getRewards = async () => {
    const params = {
      page: currentPage,
      page_size: rowsPerPage,
    };
    setLoading(true)
    const rewardData = await getRewardNewApi(params, 'pending');
    if (rewardData.results && rewardData.results.length > 0) {
      // gift_name
      let mod_res = rewardData.results?.map(e => {
        e.gift_name =
          e.level_award?.gift_display !== null
            ? e.level_award.gift_display.description
            : '';

        let myDate = moment(e.updated_at).format('MM-DD-YYYY');
        e.created_at = myDate;

        return e;
      });

      let sorting = mod_res.sort((a, b) =>
        a.user.first_name.localeCompare(b.user.first_name),
      );

      let test = sorting.sort(function (a, b) {

        if (a.status === 'rejected') return 1;
        else if (b.status === 'rejected') return -1;
        else return a - b;
      });
      setRewardData(test);
      // setRewardData(new_mod_res);
    } else {
      setRewardData([]);
    }
    setRewardCount(rewardData.count)
    setRewardNextPage(rewardData.next)
    setRewardPreviousPage(rewardData.previous)
    setLoading(false)
  }

  const getGifts = async () => {
    const params = {
      page: currentPage,
      page_size: rowsPerPage,
    };
    setLoading(true)
    const giftData = await getGiftData(params, 'pending');
    if (giftData.results && giftData.results.length > 0) {

      setGiftData(giftData.results);
    } else {
      setGiftData([]);
    }
    setGiftCount(giftData.count)
    setGiftNextPage(giftData.next)
    setGiftPreviousPage(giftData.previous)
    setLoading(false)
  }

  const getProductPurchases = async () => {
    const params = {
      page: currentPage,
      page_size: rowsPerPage,
    };
    setLoading(true)
    const purchasedData = await getPurchases(params, 'pending');
    if (purchasedData && purchasedData.results && purchasedData.results.length > 0) {
      setPurchaseData(purchasedData.results);
    } else {
      setPurchaseData([])
    }
    setPurchaseCount(purchasedData.count)
    setPurchaseNextPage(purchasedData.next)
    setPurchasePreviousPage(purchasedData.previous)
    setLoading(false)
  }

  useEffect(() => {
    getGifts()
    getRewards()
    getProductPurchases()
  }, [currentPage, refresh]);

  //Accept Reward Data
  const acceptEarn = async id => {
    var paramdata = {
      status: 'accepted',
    };
    console.log(id.original.id)

    if (id.original.status) {
      let res = await changeRewardStatus(id.original.id, paramdata);
    } else {
      paramdata = {
        accepted: true,
      };
    }
    getRewards()
    setCurrentPage(1)

  }

  //Reject Reward Data
  const rejectEarn = async id => {
    var paramdata = {
      status: 'rejected',
    };
    if (id.original.status) {
      let res = await changeRewardStatus(id.original.id, paramdata);
    } else {
      paramdata = {
        accepted: false,
      };
    }
    getRewards()
    setCurrentPage(1)
  };

  //Accept Gift Data
  const acceptGift = async id => {
    var paramdata = {
      status: 'accepted',
    };

    if (id.original.status) {
      let res = await changeGiftStatus(id.original.id, paramdata);
    } else {
      paramdata = {
        accepted: true,
      };
    }
    getGifts()
    setCurrentPage(1)
  }

  //Reject Gift Data
  const rejectGift = async id => {
    var paramdata = {
      status: 'rejected',
    };

    if (id.original.status) {
      let res = await changeGiftStatus(id.original.id, paramdata);
    } else {
      paramdata = {
        accepted: true,
      };
    }
    getGifts()
    setCurrentPage(1)
  }

  //Accept Purchase Data
  const acceptPurchase = async id => {
    if (id.original.points_paid > id.original.user_current_points) {
      toast.error("User doesn't have sufficient points to purchase this product!")
      return
    }
    var paramdata = {
      accepted: true,
    };

    let res = await ProductPurchaseNew(id.original.id, paramdata);

    getProductPurchases()
    setCurrentPage(1)
  };

  //Reject Purchase Data
  const rejectPurchase = async id => {
    var paramdata = {
      accepted: false,
    };
    let res = await ProductPurchaseNew(id.original.id, paramdata);
    getProductPurchases()
    setCurrentPage(1)
  };


  const Acceptslected = async () => {
    if (columns === '1') {
      const newBonus = await bulkApprove({
        reward_ids: selectedRow,
        status: 'accepted'
      });
      setSelectedRow([])
      setCurrentPage(1)
      getRewards()
    } else if (columns === "2") {
      const newBonus = await bulkApproveGift({
        gift_ids: selectedRow,
        status: 'accepted'
      });
      setSelectedRow([])
      setCurrentPage(1)
      getGifts()
    } else if (columns === "3") {
      const newBonus = await bulkApprovePurchase({
        purchase_ids: selectedRow,
        accepted: true
      });
      setSelectedRow([])
      setCurrentPage(1)
      getProductPurchases()
    }
  };

  const Rejectslected = async () => {
    if (columns === '1') {
      const newBonus = await bulkApprove({
        reward_ids: selectedRow,
        status: 'rejected'
      });
      setSelectedRow([])
      setCurrentPage(1)
      getRewards()
    } else if (columns === "2") {
      const newBonus = await bulkApproveGift({
        gift_ids: selectedRow,
        status: 'rejected'
      });
      setSelectedRow([])
      setCurrentPage(1)
      getGifts()
    } else if (columns === "3") {
      const newBonus = await bulkApprovePurchase({
        purchase_ids: selectedRow,
        accepted: false
      });
      setSelectedRow([])
      setCurrentPage(1)
      getProductPurchases()
    }
  };

  const fetchData = async () => {
    // const RewardedData = await getReward();

    const RewardedData = await getRewardNewApi();

    const PurchasedData = await getPurchases();

    var acceptreward_earned = [];
    var rejectreward_earned = [];
    var acceptreward_purchased = [];
    var rejectreward_purchased = [];
    if (RewardedData.length > 0) {
      for (var i = 0; i < RewardedData.length; i++) {

        RewardedData[i].user.id = RewardedData[i].id;
        if (RewardedData[i].accepted == false) {
          rejectreward_earned.push(RewardedData.results[i]);
        } else if (RewardedData.results[i].accepted == true) {
          acceptreward_earned.push(RewardedData.results[i]);
        }
      }
    }
    if (PurchasedData.results.length > 0) {
      for (var j = 0; j < PurchasedData.results.length; j++) {
        PurchasedData.results[j].user.rewardid = PurchasedData.results[j].id;
        if (PurchasedData.results[j].status == 'pending') {
          rejectreward_purchased.push(PurchasedData[j]);
        } else if (PurchasedData[j].status == 'pending') {
          acceptreward_purchased.push(PurchasedData[j]);
        }
      }
    }

    setacceptearnData(acceptreward_earned);
    setrejectearnData(rejectreward_earned);
    setacceptpurchaseData(acceptreward_purchased);
    setrejectpurchaseData(rejectreward_purchased);

    setRewardData(RewardedData);
    setPurchaseData(PurchasedData.results);
  };


  const tablerewardcolumns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name}{' '}
                {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Received', accessor: 'points', Cell: ({ value }) => (
          <div>
            + {value} Points
          </div>
        ),
      },
      { Header: 'Detail', accessor: 'code' },
      {
        Header: 'Date',
        accessor: 'created_at',
      },
      {
        Header: 'Status',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            <>
              <Button
                onClick={() => {
                  acceptEarn(row);
                }}
                label="Accept "
                type="primary"
              />
              <Button
                onClick={() => {
                  rejectEarn(row);
                }}
                label="Reject"
                type="secondary"
              />
            </>
          </div>
        ),
      },
    ],
    [],
  );

  const tablegiftcolumns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name}{' '}
                {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Level', accessor: 'level', Cell: ({ value }) => (
          <div className='text-uppercase'>{value}
          </div>
        ),
      },
      { Header: 'Detail', accessor: 'code' },
      {
        Header: 'Date',
        accessor: 'created_at', Cell: ({ value }) => (
          <div>{moment(value).format('MM-DD-YYYY')}</div>
        )
      },
      {
        Header: 'Status',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            <>
              <Button
                onClick={() => {
                  acceptGift(row);
                }}
                label="Accept "
                type="primary"
              />
              <Button
                onClick={() => {
                  rejectGift(row);
                }}
                label="Reject"
                type="secondary"
              />
            </>
          </div>
        ),
      },
    ],
    [],
  );

  const tablepurchasecolumns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name} {' '}
                {value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Confirmation',
        accessor: 'product_id',
      },
      {
        Header: 'PAID', accessor: 'points_paid', Cell: ({ value }) => (
          <div>
            - {value} Points
          </div>
        ),
      },

      {
        Header: 'PURCHASED',
        accessor: 'product_name',
      },
      {
        Header: 'Status',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            <>
              <Button
                onClick={() => {
                  acceptPurchase(row);
                }}
                label="Accept "
                type="primary"
              />
              <Button
                onClick={() => {
                  rejectPurchase(row);
                }}
                label="Reject"
                type="secondary"
              />
            </>
          </div>
        ),
      },
    ],
    [],
  );

  const handleApproveSelected = (event, id, data, action = 'single') => {
    console.log(id)
    let temp = [...selectedRow]
    if (event.target.checked) {
      if (action === 'all') {
        temp = data.map(item => item.id)
      } else {
        if (!temp.includes(id)) {
          temp.push(id)
        }
      }
    } else {
      if (action === 'all') {
        temp = []
      } else {
        let index = temp.indexOf(id)
        temp.splice(index, 1)
      }
    }
    setSelectedRow(temp)
  }

  return (
    <ContentWrapper breadcrumb="Dashboard / Approve reward points">
      <div className="flex flex-col w-full">
        <div style={{ display: 'flex' }}>
          <h5 className='mb-4'>Automate approvals</h5>
        </div>
        <div
          style={{
            height: '45px',
            borderRadius: '10px',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'flex' }}>
            <div
              className={columns === '1' ? 'bg-tableHeaderBg p-2 cursor-pointer' : 'p-2 cursor-pointer'}
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('1')}>
              Rewards ({rewardCount})
            </div>
            <div
              className={columns === '2' ? 'bg-tableHeaderBg p-2 cursor-pointer' : 'p-2 cursor-pointer'}
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('2')}>
              Gifts ({giftCount})
            </div>
            <div
              className={
                columns === '3' ? 'bg-tableHeaderBg p-2 ml-1 cursor-pointer' : 'p-2 ml-1 cursor-pointer'
              }
              style={{ borderRadius: '10px 10px 0px 0px' }}
              onClick={() => getuseData('3')}>
              Purchase ({purchaseCount})
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              onClick={Acceptslected}
              label="ACCEPT SELECTED"
            />
            <Button
              type="secondary"
              onClick={Rejectslected}
              label="REJECT SELECTED"
            />
          </div>
        </div>

        {columns == '1' ? (
          <><Table columns={tablerewardcolumns} data={RewardData} onSelectedRowChange={handleApproveSelected} loading={loading} selectedRow={selectedRow} />
            {RewardData && RewardData.length === 0 && !loading ? <div>
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${rewardpreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${rewardNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setCurrentPage(currentPage + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </>
        ) : columns == '2' ? <><Table columns={tablegiftcolumns} data={GiftData} onSelectedRowChange={handleApproveSelected} selectedRow={selectedRow} loading={loading} />
          {GiftData && GiftData.length === 0 && !loading ? <div>
            <p className='text-center mt-5'>No data found</p>
          </div> : <div>
            <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
              <button
                style={{
                  backgroundColor: '#970c24d9',
                  padding: 10,
                  color: '#fff',
                  borderRadius: 5
                }}
                className={`text-xs text-center text-wine ${giftpreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}>
                Show previous 10 results
              </button>

              <button
                style={{
                  backgroundColor: '#970c24d9',
                  padding: 10,
                  color: '#fff',
                  borderRadius: 5
                }}
                className={`text-xs text-center text-wine ${giftNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                onClick={() => setCurrentPage(currentPage + 1)}>
                Show next 10 results
              </button>
            </div>
          </div>}
        </> : (
          <><Table columns={tablepurchasecolumns} data={PurchaseData} onSelectedRowChange={handleApproveSelected} selectedRow={selectedRow} loading={loading} />
            {PurchaseData && PurchaseData.length === 0 && !loading ? <div>
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${purchasepreviousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${purchaseNextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setCurrentPage(currentPage + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}
          </>
        )}
      </div>
    </ContentWrapper >
  );
};

export default Approve;
