import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { ContentWrapper, Button } from '@components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as User } from '@images/user.svg';
import { getShopUsers, toggleUserActiveStatus } from '@apis';
import { Switch } from '@components';
import { SearchContext } from '@context/searchContext';
import { Circles, Puff, Rings } from 'react-loader-spinner';
import './users.css';
import { API_HOST } from '@apis/';
import axios from 'axios';
const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = ({ columns, data }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({ columns, data }, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  return (
    <table {...getTableProps()} className="min-w-full">
      <thead className="bg-tableHeaderBg p-4">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                if (cell.column.Header == 'Active Status') {
                  return (
                    <td>
                      <Switch
                        enabled={row.values.is_active}
                        onChange={() =>
                          toggleUserActiveStatus(row.original.user.id)
                        }
                      />
                    </td>
                  );
                }

                return (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 whitespace-nowrap text-sm">
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ShopUsers = () => {
  const [data, setData] = useState([]);
  const [sloading, setSloading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');

  const history = useHistory();
  const rowclick = id => {
    var id = id;
    history.replace('/shop-users/' + id);
  };

  const { userResults, setLoading, loading } = useContext(SearchContext);

  const getUsers = () => {
    const token =
      localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');
    setSloading(true);
    let config = {
      url: API_HOST + `/admin-api/v1/shop-users/?page=${pageNumber}`,
      //      url: API_HOST + '/admin-api/v1/shop-users/?page=${pageNumber}',
      method: 'get',
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios(config).then(res => {
      let shortData = res.data.results?.sort((a, b) =>
        a.user.first_name.localeCompare(b.user.first_name),
      );

      setData(shortData);

      setNextPage(res.data.next);
      setPreviousPage(res.data.previous);
      setSloading(false);
    });
  };

  useEffect(() => {
    getUsers();
  }, [pageNumber]);

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: ({ row }) => (
          <div className="flex items-center cursor-pointer" onClick={() => rowclick(row.original.id)}>
            <div>
              {row.values.user.profile.profile_image !== null ? (
                <img
                  src={row.values.user.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
              ) : (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1077/1077063.png"
                  className="user-avatar"
                  alt="User avatar"
                />
              )}
            </div>
            <div className="px-4">
              <p className="text-left">
                {row.values.user.first_name} {' '} {row.values.user.last_name}
              </p>
            </div>
          </div>
        ),
      },
      { Header: 'Pending', accessor: 'pending_approval_count' },
      { Header: 'Purchases', accessor: 'purchases_count' },
      { Header: 'Level', accessor: 'level_display' },
      { Header: 'Points', accessor: 'total_points' },
      {
        Header: '$ Spent', accessor: 'total_money_spent', Cell: ({ value }) => (
          <div>
            <p className="text-left">
              {value === null ? '0' : value}
            </p>
          </div>
        ),
      },
      { Header: 'Active Status', accessor: 'is_active' },
    ],
    [],
  );

  return (
    <div>
      {!sloading && (
        <ContentWrapper breadcrumb="Dashboard / Users" searchfield="Tes123">
          <div className="flex flex-col w-full">
            <Table columns={columns} data={data} />
            {data && data.length === 0 ? < div >
              <p className='text-center mt-5'>No data found</p>
            </div> : <div>
              <div className="bg-tableHeaderBg py-2 md:flex md:justify-center gap-x-10 px-4">
                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${previousPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}>
                  Show previous 10 results
                </button>

                <button
                  style={{
                    backgroundColor: '#970c24d9',
                    padding: 10,
                    color: '#fff',
                    borderRadius: 5
                  }}
                  className={`text-xs text-center text-wine ${nextPage !== null ? 'cursor-pointer' : 'pointer-none'}`}
                  onClick={() => setPageNumber(pageNumber + 1)}>
                  Show next 10 results
                </button>
              </div>
            </div>}

          </div>
        </ContentWrapper>
      )}
    </div>
  );
};

export default ShopUsers;
