import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@components';
import useStore from '@context/useStore';
import { searchUsers } from '@apis';
import { ReactComponent as Search } from '@images/search.svg';
import { resolveValue } from 'react-hot-toast';
import axios from 'axios';
import { SearchContext } from '@context/searchContext';
import { API_HOST } from '@apis/';

const ContentWrapper = ({
  children,
  breadcrumb,
  hasSearch = true,
  addProduct = null,
  removeShopUser = null,
  productDetail = null,
  onButtonOneClick,
  onButtonTwoClick,
  onButtonThreeClick,
  isEditedView = false,
  searchfield,
}) => {
  const { storeDetails } = useStore();
  const history = useHistory();

  // useEffect(async ()=>{
  //   if(hasSearch){
  //   // data = await searchUsers()
  //   // setUsers(data)
  //   }
  // },[])

  const { userResults, setUserResults, loading, setLoading } = useContext(
    SearchContext,
  );

  const searchFunction = async e => {
    const data = await searchUsers(e.target.value);
    setUserResults(data.results);
  };

  const showBreadcrum = () => {
    if (breadcrumb.map) {
      return (
        <p className="text-xs text-menu-title">
          {breadcrumb.map((item, key) => {
            if (key == breadcrumb.length - 1)
              return <span key={key}>{item}</span>;
            else
              return (
                <>
                  <span key={key} className="tabbed-link">
                    {item}
                  </span>{' '}
                  /{' '}
                </>
              );
          })}
        </p>
      );
    } else {
      return <p className="text-xs text-menu-title">{breadcrumb}</p>;
    }
  };

  return (
    <>
      <div className="hidden lg:flex w-full pr-6">
        <div className="w-3/5 hidden lg:flex px-8">
          <div className="flex flex-1 flex-col justify-center">
            <div className="flex flex-row">
              <p className="text-md w-max font-semibold pb-4">
                {storeDetails?.name || 'Store name not setup'}
              </p>
              {!storeDetails?.name && (
                <Button
                  type="link"
                  label="Setup now"
                  onClick={() => history.push('/store-setup')}
                />
              )}
            </div>
            {showBreadcrum()}
          </div>
        </div>
        <div className="w-2/5 h-full hidden lg:flex items-center justify-end pl-5">
          {addProduct && (
            <Button
              onClick={() => onButtonOneClick()}
              label="ADD PRODUCT"
              type="primary"
              style={{ width: '115px' }}
            />
          )}
          {hasSearch ? (
            <div className="relative w-full">
              {/* <input
                className="border border-gray-100 focus:outline-none focus:border-indigo-700 rounded w-full text-sm text-gray-500 bg-gray-100 pl-12 py-2"
                type="text"
                placeholder="SEARCH USER"
                onChange={e => searchFunction(e)}
              />
              <div className="text-gray-500 absolute ml-4 top-0 bottom-0 right-6 m-auto w-4 h-4">
                <Search height={18} />
              </div> */}
            </div>
          ) : !removeShopUser ? (
            !productDetail ? (
              <div className="flex flex-row justify-between items-center">
                <div className="flex-initial mx-3">
                  <Button
                    onClick={() => onButtonOneClick()}
                    label="SAVE CHANGES"
                    type="primary"
                    disabled={!isEditedView}
                  />
                </div>
                <div className="flex-initial mx-3">
                  <Button
                    onClick={() => onButtonTwoClick()}
                    label="CANCEL"
                    type="secondary"
                    disabled={!isEditedView}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-row justify-between items-center">
                {/* <div className="flex-initial mx-3">
                  <Button
                    onClick={() => onButtonOneClick()}
                    label="Save Changes"
                    type="primary"
                    disabled={!isEditedView}
                  />
                </div>
                <div className="flex-initial mx-3">
                  <Button
                    onClick={() => onButtonTwoClick()}
                    label="Cancel"
                    type="secondary"
                    disabled={!isEditedView}
                  />
                </div> */}
                <div className="flex-initial mx-3">
                  <Button
                    onClick={() => onButtonThreeClick()}
                    label="REMOVE PRODUCT"
                    type="error"
                  />
                </div>
              </div>
            )
          ) : (
            <div className="inline-flex">
              <Button
                onClick={() => onButtonTwoClick()}
                label="ADD MONEY SPENT"
                type="primary"
              />
              <Button
                onClick={() => onButtonOneClick()}
                label="REMOVE"
                type="error"
              />
            </div>
          )}
        </div>
      </div>
      <div className="container py-10 h-64 px-8">
        <div className="w-full h-full rounded">{children}</div>
      </div>
    </>
  );
};

export default ContentWrapper;
