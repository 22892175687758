import {ReactComponent as Home} from '@images/home.svg';
import {ReactComponent as Analytics} from '@images/analytics.svg';
import {ReactComponent as Gift} from '@images/gift.svg';
import {ReactComponent as Inventory} from '@images/inventory.svg';
import {ReactComponent as Marketing} from '@images/marketing.svg';
import {ReactComponent as Setup} from '@images/setup.svg';
import {ReactComponent as PointsSetup} from '@images/pointsSetup.svg';
import {ReactComponent as User} from '@images/user.svg';

export const storeMenus = [
  {
    title: 'Home',
    breadcrumb: 'Dashboard /',
    path: '/',
    icon: ({isActive}) => (
      <Home className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`} />
    ),
  },
  {
    title: 'Store setup',
    breadcrumb: 'Dashboard / Loyalty program and Store set up',
    path: '/store-setup',
    icon: ({isActive}) => (
      <Setup
        className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
      />
    ),
  },
  {
    title: 'Points setup',
    breadcrumb: 'Dashboard / Loyalty program set up',
    path: '/points-setup',
    icon: ({isActive}) => (
      <PointsSetup
        className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
      />
    ),
  },
  {
    title: 'Approve redeems ',
    breadcrumb: 'Dashboard / Approve reward points',
    path: '/approve-redeems',
    icon: ({isActive}) => (
      <Gift
        className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
        height={19}
      />
    ),
  },
  {
    title: 'Shop users',
    breadcrumb: 'Dashboard / Users',
    path: `/shop-users/`,
    icon: ({isActive}) => (
      <User className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`} />
    ),
  },
  {
    title: 'Inventory',
    breadcrumb: 'Dashboard / Inventory',
    path: '/products',
    icon: ({isActive}) => (
      <Inventory
        className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
      />
    ),
  },
];
// export const analyticsMenus = [
//   {
//     title: 'Marketing',
//     breadcrumb: 'Dashboard / Marketing',
//     path: '/marketing',
//     icon: ({isActive}) => (
//       <Marketing
//         className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
//       />
//     ),
//   },
//   {
//     title: 'Analytics',
//     breadcrumb: 'Dashboard / Analytics',
//     path: '/analytics',
//     icon: ({isActive}) => (
//       <Analytics
//         className={`fill-current ${isActive ? 'text-red' : 'text-menu'}`}
//       />
//     ),
//   },
// ];
