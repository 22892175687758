import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import moment from 'moment';
import { ContentWrapper, Button } from '@components';
import {
  getreedemed,
  getReward,
  Acceptapi,
  getRewardNewApi,
  changeRewardStatus,
  getPurchases,
  ProductPurchaseNew,
  changeGiftStatus
} from '@apis';
import { useMutation } from 'react-query';
import { ReactComponent as Gift } from '@images/gift.svg';
import { ReactComponent as User } from '@images/user.svg';
import { ReactComponent as Basket } from '@images/basket.svg';
import { ReactComponent as Star } from '@images/star.svg';
import debby from '@images/debby.png';
import { continueStatement } from '@babel/types';
import useStore from '@context/useStore';
import axios from 'axios';

import { API_HOST } from '@apis/';
const auth_token =
  localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = ({ columns, data, loading }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({ columns, data }, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);
  });

  return (
    <table {...getTableProps()} className="min-w-full">
      <thead className="bg-tableHeaderBg p-4">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="text-left text-menu font-normal uppercase text-sm px-4 py-2">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {loading ? <div className='mt-5 pt-5'>Loading....</div> : <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 whitespace-nowrap text-sm">
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>}
    </table>
  );
};

const Dashboard = () => {
  const { storeDetails } = useStore();

  const [acceptreward, setacceptrewardData] = useState([]);
  const [rejectreward, setrejectrewarddData] = useState([]);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(false)

  const [refresh, setRefresh] = useState(false);
  const [stats, setData] = useState([
    {
      id: '1',
      title: 'Gifts Redeemed',
      quantity: 0,
      icon: () => <Gift className="fill-current text-white" />,
    },
    {
      id: '2',
      title: 'Points Redeemed',
      quantity: 0,
      icon: () => <Star className="fill-current text-white" />,
    },

    {
      id: '3',
      title: 'New Requests',
      quantity: 0,
      icon: () => <Basket className="fill-current text-white" />,
    },
  ]);
  const [_rewardData, setrewardData] = useState([]);
  const [rewardData, setRewardData] = useState([]);

  const RewardAccept = useMutation(params => Acceptapi(params));

  const fetchRewards = () => {
    const token =
      localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');


    setLoading(true);
    let config = {
      url: API_HOST + `/admin-api/v1/latest-stats`,
      method: 'get',
      headers: {
        "Authorization": `Token ${token}`,
        "Content-Type": "application/json"
      },
    };
    axios(config).then(res => {
      if (res.data && res.data && res.data.length > 0) {
        setRewardData(res.data);
      } else {
        setRewardData([]);
      }
      setLoading(false);
    });

  };

  useEffect(() => {
    fetchRewards()
  }, [refresh]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const token =
        localStorage.getItem('nls:auth') || sessionStorage.getItem('nls:auth');

      // const data = await

      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Token ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      let res = await fetch(
        'https://store.nextleveltechapp.com/admin-api/v1/dashboard/counts',
        requestOptions,
      )
        .then(response => response.json())
        .then(result => {
          return result;
        })
        .catch(error => {
          return '';
        });

      if (res !== '') {
        let data = [
          {
            id: '1',
            title: 'Gifts Redeemed',
            quantity: res.gift_redeemed_count,
            icon: () => <Gift className="fill-current text-white" />,
          },
          {
            id: '2',
            title: 'Points Redeemed',
            quantity: res.total_point_redeemed,
            icon: () => <Star className="fill-current text-white" />,
          },

          {
            id: '3',
            title: 'New Requests',
            quantity: res.new_request_count,
            icon: () => <Basket className="fill-current text-white" />,
          },
        ];

        setData(data);
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  //Accept Reward Data
  const acceptEarn = async id => {

    if (id.original.data_type === 'reward') {
      var paramdata = {
        status: 'accepted',
      };
      let res = await changeRewardStatus(id.original.id, paramdata);
    } else if (id.original.data_type === 'product') {
      var paramdata = {
        accepted: true,
      };
      let res = await ProductPurchaseNew(id.original.id, paramdata);
    } else if (id.original.data_type === 'gift') {
      var paramdata = {
        status: 'accepted',
      };
      let res = await changeGiftStatus(id.original.id, paramdata);
    }
    fetchRewards()
    setTimeout(() => {
      setRefresh(!refresh)
    }, [1500])

  }

  //Reject Reward Data
  const rejectEarn = async id => {
    if (id.original.data_type === 'reward') {
      var paramdata = {
        status: 'rejected',
      };
      let res = await changeRewardStatus(id.original.id, paramdata);
    } else if (id.original.data_type === 'product') {
      var paramdata = {
        accepted: false,
      };
      let res = await ProductPurchaseNew(id.original.id, paramdata);
    } else if (id.original.data_type === 'gift') {
      var paramdata = {
        status: 'rejected',
      };
      let res = await changeGiftStatus(id.original.id, paramdata);
    }
    fetchRewards()
    setTimeout(() => {
      setRefresh(!refresh)
    }, [1500])

  };

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user_detail',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <div>
              {value.profile && value.profile.profile_image !== null ?
                <img
                  src={value.profile.profile_image}
                  className="user-avatar"
                  alt="User avatar"
                />
                :
                <img
                  src='https://cdn-icons-png.flaticon.com/512/1077/1077063.png'
                  className="user-avatar"
                  alt="User avatar"
                />
              }
            </div>
            <div className="px-4">
              <p className="text-left">
                {value.first_name}{' '}{value.last_name}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Confirmation',
        accessor: 'code',

        Cell: ({ value }) => (
          <p className="text-xs text-left tracking-widest">{value}</p>
        ),
      },
      {
        Header: 'Points', accessor: 'points_paid', Cell: ({ row }) => (
          <p className="text-xs text-left tracking-widest">{row && row.values && row.values.data_type === 'product' ? `- ${row && row.values && row.values.points_paid}` : row && row.values && row.values.data_type === 'reward' ? `+ ${row && row.values && row.values.level_award.points}` : ''}</p>
        ),
      },

      {
        Header: 'Discount',
        accessor: 'level_award',
        Cell: ({ value }) => (
          <p className="text-xs text-left tracking-widest">{value && value.discount !== undefined && `${value.discount}%`}</p>
        ),
      },
      {
        Header: 'Received For',
        accessor: 'data_type',
        Cell: ({ value }) => (
          <div className="bg-tableHeaderBg px-2 py-1 rounded">
            <p className="text-btnSecondaryText text-xs text-center">{value === 'product' ? 'Product Purchase' : value === 'reward' ? 'Reward Claim' : 'Gift Claim'}</p>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'accepted',
        Cell: ({ row }) => (
          <div className="inline-flex items-center">
            <>
              <Button
                onClick={() => {
                  acceptEarn(row);
                }}
                label="Accept "
                type="primary"
              />
              <Button
                onClick={() => {
                  rejectEarn(row);
                }}
                label="Reject"
                type="secondary"
              />
            </>
          </div>
        ),
      },
    ],
    [],
  );

  const Acceptslected = async () => {
    setrewardData(acceptreward);
  };
  const Rejectslected = async () => {
    setrewardData(rejectreward);
  };
  const fetchData = async () => {
    const resp = await getreedemed();
    const rewardlst = await getReward();

    stats[0]['quantity'] = resp.gifts_redeemed;
    stats[1]['quantity'] = resp.points_redeemed;
    setData(stats);
    var acceptreward_1 = [];
    var rejectreward_1 = [];
    if (rewardlst?.results.length > 0) {
      for (var i = 0; i < rewardlst.results.length; i++) {
        rewardlst.results[i].user.rewardid = rewardlst.results[i].id;
        if (rewardlst.results[i].accepted == false) {
          rejectreward_1.push(rewardlst.results[i]);
        } else if (rewardlst.results[i].accepted == true) {
          acceptreward_1.push(rewardlst.results[i]);
        }
      }
    }
    setacceptrewardData(acceptreward_1);
    setrejectrewarddData(rejectreward_1);
    //    setrewardData([]);
    setrewardData(rewardlst?.results);
    setKey(Math.random());
  };

  return (
    <ContentWrapper breadcrumb="Dashboard /">
      <div className="flex flex-wrap" key={key}>
        {stats.map(stat => (
          <div
            key={stat.id}
            className="flex-1 bg-red rounded-md shadow-stats mr-8 last:mr-0 mb-4 p-4 pl-6 min-h-full">
            <div className="flex flex-row items-center">
              {stat.icon()}
              <p className="text-white ml-4 font-semibold">{stat.quantity}</p>
            </div>
            <p className="text-white pt-3 uppercase font-semibold">
              {stat.title}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full">
        <div
          className="inline-flex mb-2"
          style={{ justifyContent: 'space-between' }}>
          <div className="inline-flex" />
        </div>
        <Table columns={columns} data={rewardData} prepareRow={10} loading={loading} />
        {rewardData && rewardData.length === 0 && !loading && < div >
          <p className='text-center mt-5'>No data found</p>
        </div>}
      </div>
    </ContentWrapper>
  );
};

export default Dashboard;
