import React, { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';

import useStore from '@context/useStore';
import { updateStoreApi } from '@apis';

import { ContentWrapper, Input, Card, Button } from '@components';

const getBase64 = (e, setFile) => {

  const maxSizeInBytes = 5 * 1024 * 1024;

  var file = e.target.files[0];
  if (e.target.files[0].size > maxSizeInBytes) {
    toast.error('Image size should be less than 5 MB');
    return false
  } else if (e.target.files[0]) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
};

const FileUploadButton = ({ innerRef, setFile, setEditedView, ...rest }) => (
  <div className="flex p-2">
    <input
      ref={innerRef}
      type="file"
      id="upload"
      hidden
      onChange={e => { getBase64(e, setFile); setEditedView(true); }}
    />
    <label
      htmlFor="upload"
      className="text-red text-xs uppercase cursor-pointer">
      Upload Logo
    </label>
  </div>
);

const StoreSetup = () => {
  const upload = useRef(null);
  const { storeDetails, isLoading, error, refetch } = useStore();
  const [isEditedView, setEditedView] = useState(false);

  const updateStore = useMutation(params => updateStoreApi(params), {
    onSuccess: async d => {
      await toast.success('Store Updated succesfully');
      await refetch();
    },
    onError: async err => {
      console.log('===Error updating store====', err);
      // await toast.error(err);
    },
  });

  const [storeName, setStoreName] = useState(storeDetails?.name);
  const [storeLogo, setStoreLogo] = useState(storeDetails?.logo);

  const handleStoreUpdate = async () => {
    await updateStore.mutate({
      ...storeDetails,
      name: storeName,
      logo: upload.current ? upload.current.files[0] : null,
    });
  };

  return (
    <ContentWrapper
      breadcrumb={["Dashboard", "Loyalty program and Store set up"]}
      onButtonOneClick={handleStoreUpdate}
      onButtonTwoClick={() => alert('Button Two')}
      hasSearch={false}
      isEditedView={isEditedView}>
      <div>
        <Input
          value={storeName || ''}
          onChange={e => { setStoreName(e.target.value); setEditedView(true) }}
          label="Store Name"
        />
      </div>
      <div className="grid gap-4 grid-cols-2 md:grid-cols-3">
        <Card
          title="Store QR Code"
          rightContent={<Button type="link" label="Generate" />}>
          {storeDetails?.qr_code && (
            <div>
              <img
                className="store-logo"
                src={storeDetails?.qr_code}
                alt="Store QR Code"
              />
              <a
                href={storeDetails?.qr_code}
                target="_blank"
                download
                style={{
                  color: '#990820',
                  display: 'block',
                  paddingTop: 10,
                  textAlign: 'center',
                }}>
                Download image
              </a>
            </div>
          )}
        </Card>
        <Card
          title="Store Logo"
          rightContent={
            <FileUploadButton innerRef={upload} setFile={setStoreLogo} setEditedView={setEditedView} />
          }>
          {storeLogo && (
            <div>
              <img
                className="object-contain"
                src={storeLogo}
                alt="Store Logo"
              />
            </div>
          )}
          {upload.current?.files[0] && (
            <div className="flex justify-center items-center">
              <p className="text-xs text-gray-400 ">
                {upload.current.files[0].name}
              </p>
            </div>
          )}
        </Card>
      </div>
    </ContentWrapper>
  );
};

export default StoreSetup;
